@use '../setting/index' as *;
// 1650px
// 1024px

.l-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  transition: background-color .4s;
}

.is-fixed.l-header {
  position: fixed;
}

.is-open.l-header {
  background-color: #fff;
  @include mq-min(md) {
    background: none;
  }
}

.l-header__inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
  @include mq-min(xl) {
    padding-left: min(48px, 2.666vw);
  }
  @include mq-min(lg) {
    padding-left: min(15px, 1.465vw);
  }
}

.is-fixed .l-header__inner {
  background: #fff;
  @include mq-min(lg) {
    background: rgba($color: #fff, $alpha: .7);
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
  }
}

.l-header__logo {
  display: block;
  width: 100px;
  margin-right: auto;
  margin-top: 15px;
  z-index: 5;
  @include mq-min(xl) {
    width: min(136px, 7.555vw);
  }
  @include mq-min(md) {
    width: 120px;
    margin-top: 4px;
  }
}

.is-fixed .l-header__logo {
  margin-top: 5px;
  @include mq-min(lg) {
    margin-top: 0;
  }
}

.is-fixed .l-header__logo-primary {
  display: none;
}

.is-open .l-header__logo-primary {
  display: none;
  @include mq-min(lg) {
    display: initial;
  }
}

.l-header__logo-fixed {
  display: none;
}

.is-open .l-header__logo-fixed {
  display: block;
  @include mq-min(lg) {
    display: initial;
  }
}

.is-fixed .l-header__logo-fixed {
  display: block;
}


.l-header__logo img {
  width: min(200px, 26.667vw);
}

.l-header__nav-wrap {
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  height: calc(100vh - 60px);
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-130%);
  transition: transform .4s;
  z-index: 3;
  @include mq-min(lg) {
    position: static;
    background: none;
    height: auto;
    overflow-y: initial;
    transform: none;
    transition: none;
  }
}

.is-open.l-header__nav-wrap {
  transform: translate(0);
  transition: transform .4s;
}

.l-header__nav {
  padding: 20px 30px;
  @include mq-min(lg) {
    padding: 0;
  }
}

.l-header__nav-lists {
  @include mq-min(lg) {
    display: flex;
  }
}

.l-header__nav-list {
  font-size: 1.6rem;
  padding: 10px;
  border-bottom: 1px solid #DBDBDB;
  @include mq-min(xl) {
    padding-right: min(20px, 1.111vw);
    padding-left: min(20px, 1.111vw);
  }
  @include mq-min(lg) {
    position: relative;
    font-size: clamp(1rem, 1.0294vw, 1.4rem);
    color: #fff;
    padding-top: min(10px, 0.735vw);
    padding-bottom: min(10px, 0.735vw);
    padding-right: min(10px, 0.977vw);
    padding-left: min(10px, 0.977vw);
    text-align: center;
    border: none;
  }
}

.l-header__nav-list:hover {
  @include mq-min(lg) {
    color: #D4BC90;
  }
}

.is-fixed .l-header__nav-list {
  color: #333;

  &:hover {
    color: #1F8570;
  }
}

.l-header__nav-list::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
}

.is-fixed .l-header__nav-list::before {
  @include mq-min(lg) {
    background-color: #333;
  }
}

.l-header__nav-list a {
  display: block;
  letter-spacing: 0.2em;
}

.l-header__nav-list-accent {
  display: inline-block;
  font-size: 2.4rem;
  @include google_font;
  margin-right: 20px;
  letter-spacing: 0.1em;
  @include mq-min(lg) {
    font-size: clamp(1rem, 1.176vw, 1.6rem);
    display: block;
    margin-right: 0;
  }
}


.l-header__nav-list-accent::first-letter {
  color: $accent_color_green;
  @include mq-min(lg) {
    color: #fff;
  }
}
.is-fixed .l-header__nav-list-accent::first-letter {
  @include mq-min(lg) {
    color: #333;
  }
}

.l-header__nav-list:hover .l-header__nav-list-accent::first-letter {
  @include mq-min(lg) {
    color: #D4BC90;
  }
}

.is-fixed .l-header__nav-list:hover .l-header__nav-list-accent::first-letter {
  @include mq-min(lg) {
    color: #1F8570;
  }
}

.l-header__nav-sp-menu {
  padding-left: 1em;
  @include mq-min(lg) {
    display: none;
  }
}

.l-header__nav-sp-menu-item {
  font-size: 1.4rem;
  margin-top: 8px;
}

.l-header__sp-links-wrap {
  padding: 30px 30px 60px;
  background: linear-gradient(#f1ece3 0%, #eaf4ef 100%);
  @include mq-min(lg) {
    display: none;
  }
}

.l-header__sp-links-item {
  position: relative;
  font-size: 1.4rem;
}
.l-header__sp-links-item + .l-header__sp-links-item {
  margin-top: 8px;
}

.l-header__sp-links-item span {
  position: relative;
}

.l-header__sp-links-item span::before {
  content: '';
  position: absolute;
  top: 5px;
  right: -10px;
  width: 7px;
  height: 7px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
}

.l-header__sp-tel-fax {
  font-size: 1.6rem;
  @include google_font;
  margin-top: 30px;
}

.l-header__sp-tel-fax li + li {
  margin-top: 8px;
}

.l-header__sp-button-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}

.l-header__language {
  margin-right: 35px;
  z-index: 5;
  @include mq-min(xl) {
    margin-right: min(60px, 3.333vw);
  }
  @include mq-min(lg) {
    margin-right: min(22px, 2.148vw);
    margin-left: min(20px, 1.471vw);
    cursor: pointer;
  }
}

.l-header__language span {
  position: relative;
  font-size: 1.4rem;
  @include google_font;
  color: #fff;
  letter-spacing: 0.1em;
  @include mq-min(lg) {
    font-size: clamp(1rem, 1.176vw ,1.6rem);
  }
}

.is-fixed .l-header__language span {
  color: #333;
}

.is-open .l-header__language span {
  color: #333;
}

.l-header__language span::before {
  content: '';
  position: absolute;
  top: 6px;
  right: -14px;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
}

.is-fixed .l-header__language span::before {
  border-color: #333;
}

.is-open .l-header__language span::before {
  border-color: #333;
}

.l-header__language-dropdown {
  position: absolute;
  padding-top: 8px;
  visibility: hidden;
}

.l-header__language:hover .l-header__language-dropdown {
  visibility: visible;
}

.is-open.l-header__language-dropdown {
  display: block;
}

.l-header__language-dropdown-item {
  font-size: 1.2rem;
  text-align: center;

  @include mq-min(lg) {
    font-size: 1.4rem;
  }
}

.l-header__language-dropdown-item + .l-header__language-dropdown-item {
  margin-top: 2px;
}

.l-header__language-dropdown-item a {
  display: block;
  padding: 10px 16px;
  background-color: #fff;
  @include mq-min(lg) {
    background-color: rgba($color: #fff, $alpha: .7);
    transition: background-color .3s;
  }
  &:hover {
    @include mq-min(lg) {
      background-color: rgba($color: #fff, $alpha: 1);
    }
  }
}

.l-header__sp-hamburger-menu {
  display: grid;
  place-content: center;
  width: 60px;
  height: 60px;
  background: linear-gradient(#d4bc90 0%, #cfac6b 100%);
  z-index: 5;
  @include mq-min(lg) {
    display: none;
  }
}

.l-header__sp-hamburger-menu span {
  position: relative;
  width: 30px;
  border-top: 1px solid #fff;
  transition: border-top .4s;
}

.l-header__sp-hamburger-menu span::before {
  content: '';
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  border-top: 1px solid #fff;
  transition: transform .4s;
}

.l-header__sp-hamburger-menu span::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  border-top: 1px solid #fff;
  transition: transform .4s;
}

.is-open.l-header__sp-hamburger-menu span {
  border-top: none;
  transition: border-top .4s;
}

.is-open.l-header__sp-hamburger-menu span::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 30px;
  border-top: 1px solid #fff;
  transform: rotate(-22deg);
  transition: transform .4s;
}

.is-open.l-header__sp-hamburger-menu span::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 30px;
  border-top: 1px solid #fff;
  transform: rotate(22deg);
  transition: transform .4s;
}

%header__pc-button {
  display: none;
  @include mq-min(xl) {
    width: min(190px, 10.555vw);
    height: min(100px, 5.555vw);
  }
  @include mq-min(lg) {
    display: grid;
    place-content: center;
    width: min(100px, 11.765vw);
    height: min(100px, 7.353vw);
    font-size: clamp(1rem, 1.1765vw, 1.6rem);
    color: #fff;
    text-align: center;
    letter-spacing: 0.3em;
    transition: background-color .3s;
    background-blend-mode:lighten;
  }
  &:hover {
    @include mq-min(lg) {
      background-color:rgba(255, 255, 255, 0.14);
    }
  }
}

.l-header__pc-button--reserve {
  @extend %header__pc-button;
  @include mq-min(lg) {
    background: url(/assets/images/common/header_pc_button_bg.jpg) no-repeat center / cover;
  }
}

.l-header__pc-button--air {
  @extend %header__pc-button;
  @include mq-min(lg) {
    background: url(/assets/images/common/header_fixed_pc_button_bg.jpg) no-repeat center / cover;
  }
}

%header__sp-button {
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 58px;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  letter-spacing: 0.3em;
}

.l-header__sp-button--reserve {
  @extend %header__sp-button;
  background: url(/assets/images/common/header_button_bg_sp.jpg) no-repeat center / cover;
}

.l-header__sp-button--air {
  @extend %header__sp-button;
  background: url(/assets/images/common/button_bg_sp.jpg) no-repeat center / cover;
}


// globalページ
html[lang=en] .l-header__sp-tel-fax,
html[lang=ko] .l-header__sp-tel-fax,
html[lang=zh-CN] .l-header__sp-tel-fax,
html[lang=zh-tw] .l-header__sp-tel-fax {
  margin-top: 0;
}

html[lang=en] .l-header__pc-button--reserve,
html[lang=ko] .l-header__pc-button--reserve,
html[lang=zh-CN] .l-header__pc-button--reserve,
html[lang=zh-tw] .l-header__pc-button--reserve {
  @include mq-min(lg) {
    width: min(190px, 11.765vw);
  }
}

html[lang=ko] .l-header__nav-list-accent {
  font-family: $ko_base_font;
}
html[lang=zh-CN] .l-header__nav-list-accent {
  font-family: $zh-CN_base_font;
}
html[lang=zh-tw] .l-header__nav-list-accent {
  font-family: $zh-tw_base_font;
}
@use '../setting/index' as *;

.p-global-read {
  position: relative;
  padding: 30px 0 0;

  @include mq-min(md) {
    padding: 56px 0 0;
  }
}

.p-global-read__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1060px;
    margin: auto;
  }
}

.p-global-read__text {
  font-size: 1.6rem;

  @include mq-min(md) {
    font-size: 1.8rem;
    text-align: center;
  }
}

.p-global-read__text+.p-global-read__text {
  margin-top: 1em;

  @include mq-min(md) {
    margin-top: 28px;
  }
}

.p-global-recommend {
  position: relative;
  padding-top: 60px;
}

.p-global-recommend__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-global-heading {
  text-align: center;
  @include google_font;
  font-size: 1.8rem;

  @include mq-min(md) {
    font-size: clamp(2rem, 3.125vw, 4rem);
  }
}

.p-global-heading--small {
  text-align: center;
  @include google_font;
  font-size: 1.8rem;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-global-heading__en {
  display: block;
  font-size: 4rem;

  @include mq-min(md) {
    font-size: clamp(4rem, 5.469vw, 7rem);
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-heading__sub {
  display: block;

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-recommend__media {
  margin-top: 30px;

  @include mq-min(md) {
    display: flex;
    align-items: center;
    column-gap: min(40px, 3.125vw);
    padding-left: min(20px, 1.5625vw);
    margin-top: min(40px, 3.125vw);
  }
}

.p-global-recommend__media-gradation {
  width: calc(100% + 60px);
  padding: 30px;
  margin: 30px -30px 0;
  background: linear-gradient(45deg, #f1ece3 0%, #eaf4ef 100%);

  @include mq-min(md) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    column-gap: min(40px, 3.125vw);
    padding-top: min(70px, 5.469vw);
    padding-bottom: min(70px, 5.469vw);
    padding-right: min(20px, 1.562vw);
    padding-left: 0;
    margin-top: min(70px, 5.469vw);
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.p-global-recommend__sub-heading {
  font-size: 3rem;
  @include google_font;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #A7A7A7;

  @include mq-min(md) {
    font-size: clamp(2rem, 3.125vw, 4rem);
    text-align: left;
    padding-bottom: min(26px, 2.03125vw);
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-recommend__text {
  font-size: 1.6rem;
  margin-top: 20px;

  @include mq-min(md) {
    margin-top: min(30px, 2.34375vw);
  }
}

.p-global-recommend__media-text-area {
  @include mq-min(md) {
    flex: 1;
  }
}

.p-global-recommend__image {
  display: block;
  margin-top: 20px;

  @include mq-min(md) {
    width: 53.822%;
  }
}

.p-global-recommend__image img {
  @include mq-min(md) {
    width: 100%;
  }
}

.p-global-rooms {
  position: relative;
  padding-top: 60px;
  padding-bottom: 30px;

  @include mq-min(md) {
    padding-top: min(125px, 9.766vw);
    padding-bottom: min(70px, 5.469vw);
  }
}

.p-global-rooms__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-global-rooms__heading {
  font-size: 4rem;
  @include google_font;
  text-align: center;

  @include mq-min(md) {
    font-size: clamp(4rem, 5.469vw, 7rem);
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-rooms__text {
  font-size: 1.6rem;
  margin-top: 10px;

  @include mq-min(md) {
    text-align: center;
    margin-top: 20px;
  }
}

.p-global-rooms__gradation-wrap {
  width: calc(100% + 60px);
  background: linear-gradient(135deg, #f1ece3 0%, #eaf4ef 100%);
  padding: 30px;
  margin: 30px -30px 0;

  @include mq-min(md) {
    width: 100vw;
    padding: 70px 30px;
    margin: 70px calc(50% - 50vw) 0;
  }
}

.p-global-rooms__contents {
  margin-top: 30px;

  @include mq-min(lg) {
    grid-template-rows: auto;
  }

  @include mq-min(md) {
    display: grid;
    grid-template-columns: 53.334% 1fr;
    grid-template-rows: auto;
    grid-column-gap: 5%;
    margin-top: min(70px, 5.469vw);
  }
}

.p-global-rooms__slide-area {
  @include mq-min(lg) {
    grid-area: 1 / 1 / 3 / 2;
  }

  @include mq-min(md) {
    grid-area: 1 / 1 / 3 / 2;
  }

}

.p-global-rooms__sub-heading {
  @include mq-min(lg) {
    grid-area: 1 / 2 / 2 / 3;
  }

  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 3;
  }

}

.p-global-rooms__description {
  @include mq-min(lg) {
    grid-area: 2 / 2 / 3 / 3;
  }

  @include mq-min(md) {
    grid-area: 2 / 2 / 4 / 3;
  }

}

.p-global-rooms__button {
  @include mq-min(lg) {
    grid-area: 3 / 2 / 4 / 3;
  }

  @include mq-min(md) {
    grid-area: 3 / 1 / 4 / 2;
  }

}
.p-global-rooms__note {
  font-size: 1.2rem;
  margin-top: 26px;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-global-rooms__contents--pc-reverse {
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 1fr 53.334%;
    grid-template-rows: auto;
    grid-column-gap: 5%;
    max-width: 1200px;
    margin: auto;
  }
}

.p-global-rooms__contents--pc-reverse .p-global-rooms__slide-area {
  @include mq-min(lg) {
    grid-area: 1 / 2 / 3 / 3;
  }

  @include mq-min(md) {
    grid-area: 1 / 2 / 3 / 3;
  }
}

.p-global-rooms__contents--pc-reverse .p-global-rooms__sub-heading {
  @include mq-min(lg) {
    grid-area: 1 / 1 / 2 / 2;
  }

  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.p-global-rooms__contents--pc-reverse .p-global-rooms__description {
  @include mq-min(lg) {
    grid-area: 2 / 1 / 3 / 2;
  }

  @include mq-min(md) {
    grid-area: 2 / 1 / 4 / 2;
  }
}

.p-global-rooms__contents--pc-reverse .p-global-rooms__button {
  @include mq-min(lg) {
    grid-area: 3 / 1 / 4 / 2;
  }

  @include mq-min(md) {
    grid-area: 3 / 2 / 4 / 3;
  }
}

.p-global-rooms__contents--secondary {
  @extend .p-global-rooms__contents;
}

.p-global-rooms__contents--secondary .p-global-rooms__slide-area {
  @include mq-min(lg) {
    grid-area: 1 / 1 / 5 / 2;
  }

  @include mq-min(md) {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.p-global-rooms__contents--secondary .p-global-rooms__sub-heading {
  @include mq-min(lg) {
    grid-area: 1 / 2 / 2 / 3;
  }

  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.p-global-rooms__contents--secondary .p-global-rooms__description {
  @include mq-min(lg) {
    grid-area: 2 / 2 / 3 / 3;
  }

  @include mq-min(md) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.p-global-rooms__contents--secondary .p-global-guide__note {
  @include mq-min(lg) {
    grid-area: 3 / 2 / 4 / 3;
  }

  @include mq-min(md) {
    grid-area: 3 / 2 / 4 / 3;
  }
}

.p-global-rooms__contents--secondary .p-global-rooms__button {
  @include mq-min(lg) {
    grid-area: 4 / 2 / 5 / 3;
  }

  @include mq-min(md) {
    grid-area: 3 / 1 / 4 / 2;
  }
}

.p-global-rooms__sub-heading {
  font-size: 3rem;
  @include google_font;
  margin-top: 15px;

  @include mq-min(md) {
    font-size: clamp(2rem, 3.125vw, 4rem);
    margin-top: 0;
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-rooms__description {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  margin-top: 10px;

  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 0;
  }
}

.p-global-rooms__description dt {
  width: 50%;
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;

  @include mq-min(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.p-global-rooms__description dd {
  width: 50%;
  padding: 10px;
  border-bottom: 2px solid #DBDBDB;

  @include mq-min(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.p-global-rooms__button {
  padding-right: 15px;
  margin-top: 30px;

  @include mq-min(md) {
    max-width: 283px;
    padding-right: 0;
  }
}

.p-global-guide {
  position: relative;
  padding: 30px 0;
  background: linear-gradient(135deg, #f1ece3 0%, #eaf4ef 100%);

  @include mq-min(md) {
    padding: 70px 0;
  }
}

.p-global-guide__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-global-guide__pc-grid-fast {
  margin-top: 30px;

  @include mq-min(md) {
    display: grid;
    grid-template-columns: 0.9fr 1.15fr;
    grid-template-rows: auto;
    grid-column-gap: 5%;
    margin-top: min(50px, 3.906vw);
  }
}

.p-global-guide__heading {
  grid-area: 1 / 1 / 2 / 2;
}

.p-global-guide__text {
  grid-area: 2 / 1 / 3 / 2;
}

.p-global-guide__description {
  grid-area: 3 / 1 / 4 / 2;
}

.p-global-guide__note {
  grid-area: 4 / 1 / 5 / 2;
}

.p-global-guide__image {
  grid-area: 1 / 2 / 4 / 3;
}


.p-global-guide__heading {
  font-size: 2rem;
  @include google_font;

  @include mq-min(md) {
    font-size: clamp(2rem, 3.125vw, 4rem);
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-guide__text {
  font-size: 1.4rem;
  margin-top: 15px;

  @include mq-min(md) {
    max-width: 410px;
    font-size: 1.6rem;
    margin-top: 20px;
  }
}

.p-global-guide__description {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  margin-top: 10px;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-global-guide__description dt {
  width: 50%;
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
}

.p-global-guide__description dd {
  width: 50%;
  padding: 10px;
  border-bottom: 2px solid #DBDBDB;
}

.p-global-guide__note {
  font-size: 1.2rem;
  margin-top: 26px;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-global-guide__image {
  display: block;
  margin-top: 20px;

  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-global-guide__image img {
  @include mq-min(md) {
    width: 100%;
  }
}

.p-global-guide__pc-grid-second {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #A7A7A7;

  @include mq-min(md) {
    display: grid;
    grid-template-columns: 1.15fr 0.9fr;
    grid-template-rows: auto;
    grid-column-gap: 5%;
    margin-top: min(50px, 3.906vw);
    padding-top: min(50px, 3.906vw);
  }
}

.p-global-guide__pc-grid-second .p-global-guide__heading {
  grid-area: 1 / 2 / 2 / 3;
}

.p-global-guide__pc-grid-second .p-global-guide__text {
  grid-area: 2 / 2 / 3 / 3;
}

.p-global-guide__pc-grid-second .p-global-guide__description {
  grid-area: 3 / 2 / 4 / 3;
}

.p-global-guide__pc-grid-second .p-global-guide__image {
  grid-area: 1 / 1 / 5 / 2;
}

.p-global-info {
  position: relative;
  padding: 30px 0;

  @include mq-min(md) {
    padding: 70px 0;
  }
}

.p-global-info__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-global-info__heading {
  font-size: 4rem;
  @include google_font;
  text-align: center;

  @include mq-min(md) {
    font-size: clamp(4rem, 5.469vw, 7rem);
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-info__3col {
  @include mq-min(md) {
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
    margin-top: 50px;
  }
}

.p-global-info__3col-item {
  margin-top: 30px;

  @include mq-min(md) {
    width: calc((100% - 60px) / 3);
    margin-top: 0;
  }
}

.p-global-info__heading-border {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;

  @include mq-min(md) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 2rem;
    padding: 0px 10px 4px;
  }
}

.p-global-info__description {
  font-size: 1.4rem;

  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 20px;
  }
}

.p-global-info__description dt {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #DBDBDB;
}

.p-global-info__description dd {
  padding: 10px;
}

.p-global-info__list {
  font-size: 1.4rem;

  @include mq-min(md) {
    font-size: 1.6rem;
    padding-top: 10px;
  }
}

.p-global-info__list-item {
  padding-left: 1em;
  text-indent: -1em;
  margin-top: 10px;

  @include mq-min(md) {
    margin-top: 20px;
  }
}

.p-global-info__list-item--small {
  font-size: 1.2rem;
  padding-left: 1em;
  text-indent: -1em;
  margin-top: 6px;

  @include mq-min(md) {
    font-size: 1.4rem;
    padding-left: 1.6em;
    text-indent: -0.5em;
    margin-top: 10px;
  }
}

.p-global-info__list-alert {
  color: #9F5D78;
  margin-top: 10px;
}

.p-global-info__check-time {
  padding: 30px 15px;
  margin-top: 60px;
  border: 1px solid #707070;

  @include mq-min(md) {
    padding: 3.334% 1.667%;
    margin-top: 5.834%;
  }
}

.p-global-info__sub-heading {
  font-size: 2.6rem;
  @include google_font;
  text-align: center;

  @include mq-min(md) {
    font-size: 4rem;
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-info__check-time-list {
  font-size: 1.8rem;
  @include google_font;
  text-align: center;
  margin-top: 15px;

  @include mq-min(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 30px;
  }
}

.p-global-info__check-time-list span {
  font-size: 2rem;
  padding-left: 10px;

  @include mq-min(md) {
    font-size: 3rem;
    padding-left: 20px;
  }
}

.p-global-info__policy {
  padding: 30px 15px;
  margin-top: 20px;
  border: 1px solid #707070;

  @include mq-min(md) {
    padding: 4.8334% 8.334%;
    margin-top: 30px;
  }

}

.p-global-info__text {
  font-size: 1.4rem;
  margin-top: 10px;

  @include mq-min(md) {
    font-size: 1.6rem;
    text-align: center;
    margin-top: 4.167%;
  }
}

.p-global-info__4col {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;

  @include mq-min(md) {
    flex-wrap: nowrap;
    margin-top: 4.167%;
  }
}

.p-global-info__4col-item {
  display: flex;
  flex-direction: column;
  width: calc((100% - 20px) / 2);
  font-size: 1.8rem;
  @include google_font;
  text-align: center;
  padding: 10px 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;

  @include mq-min(md) {
    row-gap: 10px;
  }
}

.p-global-info__4col-item span {
  display: block;
  font-size: 2rem;
  margin-top: auto;
}

.p-global-info__annotation {
  font-size: 1.4rem;
  margin-top: 20px;
  padding-left: 1em;
  text-indent: -0.5em;

  @include mq-min(md) {
    padding-left: 0;
    text-indent: 0;
    margin-top: 4.167%;
  }
}

.p-global-access {
  position: relative;
  padding: 30px 0;
  background: linear-gradient(135deg, #f1ece3 0%, #eaf4ef 100%);

  @include mq-min(md) {
    padding: 5.167% 0;
  }
}

.p-global-access__inner {
  padding: 0 30px;

  @include mq-min(md) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 8.333%;
    max-width: 1260px;
    margin: auto;
  }
}

.p-global-access__text-area {
  @include mq-min(md) {
    flex: 1;
  }
}

.p-global-access__heading {
  font-size: 4rem;
  @include google_font;

  @include mq-min(md) {
    font-size: clamp(4rem, 5.469vw, 7rem);
  }

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-access__text {
  font-size: 1.6rem;
  margin-top: 10px;

  @include mq-min(md) {
    margin-top: 30px;
  }
}

.p-global-access__map {
  position: relative;
  width: 100%;
  padding-top: 84.444%;
  margin-top: 30px;

  @include mq-min(md) {
    width: 58.333%;
    padding-top: 49.25%;
  }
}

.p-global-access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-global-notice {
  position: relative;
  padding: 30px 0 60px;

  @include mq-min(md) {
    padding: 70px 0 200px;
  }
}

.p-global-notice__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-global-notice__heading {
  font-size: 4rem;
  text-align: center;
  @include google_font;

  &::first-letter {
    color: $accent_color_green;
  }
}

.p-global-notice__description ul {
  margin-top: 10px;

  @include mq-min(md) {
    margin-top: 20px;
  }
}

.p-global-notice__description ul li {}

.p-global-notice__description dt {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 30px;
  padding: 0 5px 10px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;

  @include mq-min(md) {
    font-size: 2rem;
    margin-top: 50px;
    padding: 0 20px 20px;
  }
}

.p-global-notice__description dd {
  font-size: 1.4rem;
  padding: 10px 5px 0;

  @include mq-min(md) {
    font-size: 1.6rem;
    padding: 20px 50px 0;
  }
}

// globalページ swiper
%global-swiper {
  width: calc(100% + 60px);
  margin: 0 -30px;

  @include mq-min(md) {
    width: min(640px, 100%);
    height: fit-content;
    margin: 0;
  }
}

.p-global-rooms__slide-wrap--single {
  @extend %global-swiper;
}

.p-global-rooms__slide-wrap--semi-double {
  @extend %global-swiper;
}

.p-global-rooms__slide-wrap--twin {
  @extend %global-swiper;
}

.p-global-rooms__slide-wrap--corner-twin {
  @extend %global-swiper;
}

.p-global-rooms__slide-wrap--phiten {
  @extend %global-swiper;
}

.p-global-rooms__slide-wrap--barrier-free {
  @extend %global-swiper;
}

.p-global-rooms__slide-wrap--annex {
  @extend %global-swiper;
}


.p-global-rooms__slide-area {
  height: fit-content;
}

.p-global-rooms__slide-area .swiper-button-next,
.p-global-rooms__slide-area .swiper-button-prev {
  color: #333;
  width: 16px;
  height: 40px;

  @include mq-min(md) {
    width: 32px;
    height: 80px;
  }

}

.p-global-rooms__slide-area .swiper-button-next:after,
.p-global-rooms__slide-area .swiper-button-prev:after {
  background: rgba(255, 255, 255, .5);
  font-size: 1.4rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq-min(md) {
    font-size: 2.8rem;
    transition: background-color .3s;
  }
}

.p-global-rooms__slide-area .swiper-button-next:hover:after,
.p-global-rooms__slide-area .swiper-button-prev:hover:after {
  @include mq-min(md) {
    background: rgba(255, 255, 255, 1);
  }
}

.p-global-rooms__slide-area .swiper-button-next {
  right: 0;
}

.p-global-rooms__slide-area .swiper-button-prev {
  left: 0;
}

%swiper-thumbnail-wrap {
  padding: 0 10px;
  margin-top: 10px;

  @include mq-min(md) {
    width: min(640px, 100%);
    padding: 0;
    margin-top: 20px;
  }
}

.p-global-rooms__slide-thumbnail--single {
  @extend %swiper-thumbnail-wrap;
}

.p-global-rooms__slide-thumbnail--semi-double {
  @extend %swiper-thumbnail-wrap;
}

.p-global-rooms__slide-thumbnail--twin {
  @extend %swiper-thumbnail-wrap;
}

.p-global-rooms__slide-thumbnail--corner-twin {
  @extend %swiper-thumbnail-wrap;
}

.p-global-rooms__slide-thumbnail--barrier-free {
  @extend %swiper-thumbnail-wrap;
}

.p-global-rooms__slide-thumbnail--annex {
  @extend %swiper-thumbnail-wrap;
}

.p-global-rooms__slide-thumbnail--phiten {
  @extend %swiper-thumbnail-wrap;
}

.p-global-rooms__slide-thumbnail--single .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-start;
  }
}

.p-global-rooms__slide-thumbnail--semi-double .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-start;
  }
}

.p-global-rooms__slide-thumbnail--twin .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-start;
  }
}

.p-global-rooms__slide-thumbnail--corner-twin .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-start;
  }
}

.p-global-rooms__slide-thumbnail--phiten .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-start;
  }
}

.p-global-rooms__slide-thumbnail--barrier-free .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-start;
  }
}

.p-global-rooms__slide-thumbnail--annex .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-start;
  }
}


%swiper-thumbnail {
  opacity: .3;
  transition: opacity .3s;

  @include mq-min(md) {
    max-width: 105px;
    cursor: pointer;
  }

  &:hover {
    @include mq-min(md) {
      opacity: 1;
    }
  }

  &.swiper-slide-thumb-active {
    opacity: 1;
  }
}

.p-global-rooms__slide-thumbnail--single .swiper-slide {
  @extend %swiper-thumbnail;
}

.p-global-rooms__slide-thumbnail--semi-double .swiper-slide {
  @extend %swiper-thumbnail;
}

.p-global-rooms__slide-thumbnail--twin .swiper-slide {
  @extend %swiper-thumbnail;
}

.p-global-rooms__slide-thumbnail--corner-twin .swiper-slide {
  @extend %swiper-thumbnail;
}

.p-global-rooms__slide-thumbnail--phiten .swiper-slide {
  @extend %swiper-thumbnail;
}

.p-global-rooms__slide-thumbnail--barrier-free .swiper-slide {
  @extend %swiper-thumbnail;
}

.p-global-rooms__slide-thumbnail--annex .swiper-slide {
  @extend %swiper-thumbnail;
}

html[lang=ko] .p-global-heading__sub,
html[lang=ko] .p-global-recommend__sub-heading,
html[lang=ko] .p-global-rooms__sub-heading,
html[lang=ko] .p-global-guide__heading,
html[lang=ko] .p-global-info__heading,
html[lang=ko] .p-global-info__sub-heading,
html[lang=ko] .p-global-access__heading,
html[lang=ko] .p-global-notice__heading {
  font-family: $ko_base_font;
}

html[lang=zh-CN] .p-global-heading__sub,
html[lang=zh-CN] .p-global-recommend__sub-heading,
html[lang=zh-CN] .p-global-rooms__sub-heading,
html[lang=zh-CN] .p-global-guide__heading,
html[lang=zh-CN] .p-global-info__heading,
html[lang=zh-CN] .p-global-info__sub-heading,
html[lang=zh-CN] .p-global-access__heading,
html[lang=zh-CN] .p-global-notice__heading {
  font-family: $zh-CN_base_font;
}

html[lang=zh-tw] .p-global-heading__sub,
html[lang=zh-tw] .p-global-recommend__sub-heading,
html[lang=zh-tw] .p-global-rooms__sub-heading,
html[lang=zh-tw] .p-global-guide__heading,
html[lang=zh-tw] .p-global-info__heading,
html[lang=zh-tw] .p-global-info__sub-heading,
html[lang=zh-tw] .p-global-access__heading,
html[lang=zh-tw] .p-global-notice__heading {
  font-family: $zh-tw_base_font;
}
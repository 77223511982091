@use '../setting/index' as *;

.p-news {
  padding: 30px 0 60px;
  @include mq-min(md) {
    padding: 80px 0 200px;
  }
}

.p-news__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-news__heading {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0 10px 10px;
  @include mq-min(md) {
    max-width: 960px;
    font-size: 2rem;
    margin: auto;
  }
}


.p-news__heading time {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 6px;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-top-news__contents {
  padding-top: 20px;
  border-top: 1px solid #A7A7A7;
  @include mq-min(md) {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #A7A7A7;
  }
}


.p-top-news__image-wrap {
  @include mq-min(md) {
    max-width: 960px;
    margin: auto;
  }
}

.p-top-news__image {
  display: block;
}

.p-top-news__text-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  margin-top: 30px;
  @include mq-min(md) {
    max-width: 960px;
    margin: 50px auto 0;
  }
}

.p-news-text {
  font-size: 1.6rem;
}


.p-news__button-wrap {
  width: min(615px, 82vw);
  padding: 30px 15px 0 0;
  margin: auto;
  @include mq-min(md) {
    width: 283px;
    margin: 50px auto 0;
  }
}
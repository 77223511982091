@use "sass:map";

@mixin google_font {
  font-family: 'Marcellus', serif;
  font-weight: 400;
  font-style: normal;
}

$breakpoints: (
  'sm': 640px,
  'md': 767px,
  'lg': 1024px,
  'xl': 1260px,
  'xxl': 1921px
) !default;

/* min-width での指定 */
@mixin mq-min($name: null) {
  @if $name {
    @if map.has-key($breakpoints, $name) {
      @media screen and (min-width: #{map.get($breakpoints, $name)}) {
        @content;
      }
    }@else {
      @error "breakpoint #{$name} is not defined. use: #{map.keys($breakpoints)}";
    }
  } @else {
    @content;
  }
}

/* max-width での指定 */
@mixin mq-max($name: null) {
  @if $name {
    @if map.has-key($breakpoints, $name) {
      @media screen and (max-width: #{map.get($breakpoints, $name)}) {
        @content;
      }
    }@else {
      @error "breakpoint #{$name} is not defined. use: #{map.keys($breakpoints)}";
    }
  } @else {
    @content;
  }
}

/* min-width と max-width の両方での指定 */
@mixin mq-both($min: null, $max: null ) {
  @if $min != null and $max != null  {
    @if map.has-key($breakpoints, $min) and map.has-key($breakpoints, $max) {
      @media screen and (min-width: #{map.get($breakpoints, $min)}) and (max-width: #{map.get($breakpoints, $max)}) {
        @content;
      }
    }@else {
      @error "breakpoint is not defined. use: #{map.keys($breakpoints)}";
    }
  } @else {
    @error "need two breakpoints";
  }
}
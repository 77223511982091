@use '../setting/index' as *;
// TOPページとglobalページのMVswiper ↓↓
.p-mv__swiper-pagination {
  display: none;
  @include mq-min(md) {
    display: block;
    position: absolute;
    bottom: 15px !important;
    left: 16px !important;
    text-align: left;
    z-index: 50;
  }
}

.p-mv__swiper-pagination .swiper-pagination-bullet {
  background-color: rgba($color: #fff, $alpha: .5);
  opacity: 1 !important;
  @include mq-min(md) {
    width: 10px;
    height: 10px;
  }
}
.p-mv__swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #1F8570;
}
// TOPページ MVエリアのswiper ↑↑

// TOPページ newsエリアのswiper ↓↓
.p-top-news .swiper-button-prev {
  position: absolute;
  top: 60%;
  left: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333;
  @include mq-min(md) {
    top: 55%;
    left: 21px;
    width: 60px;
    height: 60px;
    transition: background-color .3s;
  }
  &:hover {
    @include mq-min(md) {
      background-color: #A7A7A7;
    }
  }
}
.p-top-news .swiper-button-prev::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 52.5%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  @include mq-min(md) {
    left: 54%;
    width: 18px;
    height: 18px;
  }
}

.p-top-news .swiper-button-next {
  position: absolute;
  top: 60%;
  right: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333;
  @include mq-min(md) {
    top: 55%;
    right: 21px;
    width: 60px;
    height: 60px;
    transition: background-color .3s;
  }
  &:hover {
    @include mq-min(md) {
      background-color: #A7A7A7;
    }
  }
}

.p-top-news .swiper-button-next::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 47.5%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  @include mq-min(md) {
    left: 46%;
    width: 18px;
    height: 18px;
  }
}

.p-top-news .swiper-pagination {
  position: static;
  text-align: left;
  margin-top: 20px;
  @include mq-min(md) {
    text-align: center;
    margin-top: 30px;
  }
}

.p-top-news .swiper-pagination-bullet {
  background-color: #DBDBDB;
  opacity: 1 !important;
  @include mq-min(md) {
    width: 10px;
    height: 10px;
  }
}
.p-top-news .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #333;
}
// TOPページ newsエリアのswiper ↑↑

// TOPページ facilitiesエリアのswiper ↓↓
.p-top-facilities .swiper-button-prev {
  position: absolute;
  top: 34%;
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333;
  @include mq-min(md) {
    top: 37%;
    left: -30px;
    width: 60px;
    height: 60px;
    transition: background-color .3s;
  }
  &:hover {
    @include mq-min(md) {
      background-color: #A7A7A7;
    }
  }
}
.p-top-facilities .swiper-button-prev::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 52.5%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  @include mq-min(md) {
    left: 54%;
    width: 18px;
    height: 18px;
  }
}

.p-top-facilities .swiper-button-next {
  position: absolute;
  top: 34%;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333;
  @include mq-min(md) {
    top: 37%;
    right: -33px;
    width: 60px;
    height: 60px;
    transition: background-color .3s;
  }
  &:hover {
    @include mq-min(md) {
      background-color: #A7A7A7;
    }
  }
}

.p-top-facilities .swiper-button-next::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 47.5%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  @include mq-min(md) {
    left: 46%;
    width: 18px;
    height: 18px;
  }
}

.p-top-facilities__swiper-pagination {
  position: static;
  text-align: left;
  margin-top: 16px;
  margin-left: -4px;
  @include mq-min(xl) {
    bottom: 10px !important;
    right: -240px !important;
  }
  @include mq-min(lg) {
    bottom: -30px !important;
    right: -215px !important;
  }
  @include mq-min(md) {
    position: absolute;
    bottom: -20px !important;
    right: -215px !important;
    left: initial !important;
    width: fit-content !important;
    text-align: center;
    margin-top: 30px;
    margin-left: 0;
  }
}

.p-top-facilities .swiper-pagination-bullet {
  background-color: #DBDBDB;
  opacity: 1 !important;
  @include mq-min(md) {
    width: 10px;
    height: 10px;
  }
}
.p-top-facilities .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #333;
}
// TOPページ facilitiesエリアのswiper ↑↑

// TOPページ modalのswiper ↓↓
.p-top-modal .swiper-button-prev {
  position: absolute;
  top: min(364px, 48.533vw);
  left: 0;
  width: min(32px, 4.2667vw);
  height: min(80px, 10.667vw);
  background-color: rgba($color: #fff, $alpha: .5);
  @include mq-min(md) {
    top: 50%;
    left: 0;
    width: 60px;
    height: 60px;
    transition: opacity .3s;
  }
}

.p-top-modal .swiper-button-prev:hover {
  @include mq-min(md) {
    opacity: .7;
  }
}


.p-top-modal .swiper-button-prev::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 52.5%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 10px;
  height: 10px;
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  @include mq-min(md) {
    left: 54%;
    width: 18px;
    height: 18px;
  }
}

.p-top-modal .swiper-button-next {
  position: absolute;
  top: min(364px, 48.533vw);
  right: 0;
  width: min(32px, 4.2667vw);
  height: min(80px, 10.667vw);
  background-color: rgba($color: #fff, $alpha: .5);
  @include mq-min(md) {
    top: 50%;
    right: 0;
    width: 60px;
    height: 60px;
    transition: opacity .3s;
  }
}

.p-top-modal .swiper-button-next {
  @include mq-min(md) {
    opacity: .7;
  }
}

.p-top-modal .swiper-button-next::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 47.5%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  @include mq-min(md) {
    left: 46%;
    width: 18px;
    height: 18px;
  }
}

.p-top-modal__swiper-pagination {
  position: relative;
  bottom: initial !important;
  margin-top: 20px;
  text-align: center;
  @include mq-min(md) {
    text-align: right;
  }
}

.p-top-modal__swiper-pagination .swiper-pagination-bullet {
  background-color: #DBDBDB;
  opacity: 1 !important;
  @include mq-min(md) {
    width: 10px;
    height: 10px;
  }
}
.p-top-modal__swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #333;
}
// TOPページ modalのswiper ↑↑
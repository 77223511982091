@use '../setting/index' as *;

.c-heading-lower-page {
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #fff;
  @include mq-min(md) {
    font-size: 1.8rem;
    padding-bottom: 20px;
  }
}

.c-heading-lower-page__accent {
  display: block;
  @include google_font;
  line-height: 1.15;
  &::first-letter {
    color: $accent_color_yellow;
  }
  @include mq-min(md) {
    font-size: 8rem;
  }
}

.c-heading-vertical {
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.c-heading-vertical__accent {
  display: block;
  font-size: 3rem;
  @include google_font;
  margin-bottom: 8px;
  &::first-letter {
    color: $accent_color_green;
  }
  @include mq-min(md) {
    font-size: 4rem;
  }
}


.c-heading-vertical--large {
  font-size: 1.6rem;
  text-align: center;
  @include mq-min(md) {
    font-size: 3rem;
  }
}

.c-heading-vertical__accent {
  display: block;
  font-size: 3rem;
  @include google_font;
  &::first-letter {
    color: $accent_color_green;
  }
  @include mq-min(md) {
    font-size: 4rem;
    margin-bottom: 8px;
  }
}

.c-heading-horizon {
  font-size: 1.6rem;
  font-weight: bold;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.c-heading-horizon__accent {
  display: inline-block;
  @include google_font;
  font-size: 3rem;
  margin-right: 10px;
  &::first-letter {
    color: $accent_color_green;
  }
  @include mq-min(md) {
    font-size: 4rem;
    margin-right: 20px;
  }
}

.c-heading-horizon--border-bottom {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #A7A7A7;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

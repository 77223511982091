@use '../setting/index' as *;

.js-fade-in {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity .8s ease-in-out, transform .7s ease-in-out;
}

.is-show.js-fade-in {
  opacity: 1;
  transform: translateY(0);
}


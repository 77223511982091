@use '../setting/index' as *;

.p-faq-section {
  padding: 30px 0 60px;
  @include mq-min(md) {
    padding: 60px 0 200px;
  }
}

.p-faq-section__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-faq-tab {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 10px;
  @include mq-min(md) {
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 20px;
  }
}

.p-faq-tab__item {
  width: calc((100% - 15px) / 2);
  min-height: 82px;
  font-size: 1.4rem;
  text-align: center;
  padding: 15px 10px;
  border: 1px solid #A7A7A7;
  background-position: center bottom 10px;
  background-size: 34px;
  background-repeat: no-repeat;
  transition: background-color .3s;
  @include mq-min(md) {
    min-height: 138px;
    font-size: 1.6rem;
    padding: 30px 10px 15px;
    background-size: 50px;
    background-position: center bottom 26px;
    opacity: background-color #D1E3E0;
    cursor: pointer;
  }
}

.p-faq-tab__item:hover {
  @include mq-min(md) {
    background-color: #D1E3E0;
  }
}

.is-active.p-faq-tab__item {
  background-color: #D1E3E0;
  cursor: auto;
}

.p-faq-tab__item.stay {
  background-image: url(/assets/images/faq/icon_room_key.svg);
}

.p-faq-tab__item.room {
  background-image: url(/assets/images/faq/icon_bet.svg);
}

.p-faq-tab__item.bill {
  background-image: url(/assets/images/faq/icon_bill.svg);
}

.p-faq-tab__item.eat {
  background-image: url(/assets/images/faq/icon_eat.svg);
}

.p-faq-tab__item.others {
  background-image: url(/assets/images/faq/icon_flour.svg);
}

.p-faq-content {
  display: none;
  padding: 30px 0 60px;
  @include mq-min(md) {
    max-width: 1000px;
    margin: auto;
    padding: 60px 0 100px;
  }
}
.p-faq-content.is-show {
  display: block;
}

.p-faq-content__heading {
  font-size: 1.6rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 9px;
  padding-left: 44px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  background-position: left top 3px;
  background-size: 34px;
  background-repeat: no-repeat;
  @include mq-min(md) {
    font-size: 2rem;
    padding: 20px 0 20px 70px;
    background-size: 50px;
    background-position: left center;
  }
}

.p-faq-content__heading.stay {
  background-image: url(/assets/images/faq/heading_icon_room_key.svg);
}

.p-faq-content__heading.room {
  background-image: url(/assets/images/faq/heading_icon_bet.svg);
}

.p-faq-content__heading.bill {
  background-image: url(/assets/images/faq/heading_icon_bill.svg);
  background-position: left top 1px;
  @include mq-min(md) {
    background-position: left center;
  }
}

.p-faq-content__heading.eat {
  background-image: url(/assets/images/faq/heading_icon_eat.svg);
}

.p-faq-content__heading.others {
  background-image: url(/assets/images/faq/heading_icon_flour.svg);
}

.p-faq-content__accordion dt {
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 22px 22px 20px 34px;
  border-bottom: 2px solid #DBDBDB;
  @include mq-min(md) {
    cursor: pointer;
    padding: 30px 80px;
  }
}

.p-faq-content__accordion dt::before {
  position: absolute;
  content: "Q.";
  font-size: 1.6rem;
  @include google_font;
  top: 18px;
  left: 10px;
  @include mq-min(md) {
    font-size: 2.4rem;
    top: 20px;
    left: 40px;
  }
}

.p-faq-content__accordion dt span::before,
.p-faq-content__accordion dt span::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 5px;
  width: 14px;
  height: 1px;
  background: #333;
  transition: all 0.6s;
  @include mq-min(md) {
    right: 40px;
    width: 16px;
    height: 2px;
  }
}
.p-faq-content__accordion dt span::before {
  transform: translateY(-50%) rotate(180deg);
}
.p-faq-content__accordion dt span::after {
  transform: translateY(-50%) rotate(90deg);
}
.p-faq-content__accordion dt.is-open span::before,
.p-faq-content__accordion dt.is-open span::after {
  transform: rotate(360deg);
}

.p-faq-content__accordion dd {
  display: none;
  position: relative;
  font-size: 1.4rem;
  padding: 20px 10px 20px 34px;
  background-color: #F6F6F6;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  @include mq-min(md) {
    font-size: 1.6rem;
    padding: 30px 80px;
  }
}

.p-faq-content__accordion dd::before {
  position: absolute;
  content: "A.";
  font-size: 1.6rem;
  color: $accent_color_green;
  @include google_font;
  top: 16px;
  left: 12px;
  @include mq-min(md) {
    font-size: 2.4rem;
    top: 20px;
    left: 42px;
  }
}

.p-faq-banner {
  position: relative;
  display: block;
  padding: 30px 15px;
  background: url(/assets/images/faq/banner_bg_sp.jpg) no-repeat center / cover;
  @include mq-min(md) {
    padding: 48px 40px 42px;
    background: url(/assets/images/faq/banner_bg_pc.jpg) no-repeat center / cover;
    transition: opacity .3s;
  }
}

.p-faq-banner:hover {
  @include mq-min(md) {
    opacity: .7;
  }
}

.p-faq-banner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba($color: #034a3b, $alpha: .4);
}

.p-faq-banner__inner {
  position: relative;
  @include mq-min(md) {
    max-width: 700px;
    margin: auto;
  }
}
.p-faq-banner__heading {
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-faq-banner__heading span {
  display: block;
  font-size: 3rem;
  @include google_font;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  @include mq-min(md) {
    font-size: 6rem;
    padding-bottom: 20px;
  }
}

.p-faq-banner__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
  margin-top: 10px;
  @include mq-min(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 14px;
    column-gap: 50px;
    font-size: 1.8rem;
    margin-top: 22px;
  }
}

.p-faq-banner__list-small {
  font-size: 1.4rem;
  @include mq-min(md) {
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
  }
}

.p-faq-banner__list li span {
  @include google_font;
  padding-left: 10px;
  @include mq-min(md) {
    font-size: 2rem;
  }
}
@use '../setting/index' as *;

.l-content-inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.l-content-inner--narrow {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1060px;
    margin: auto;
  }
}
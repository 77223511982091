@use '../setting/index' as *;

.l-footer {
  padding: 30px 0 100px;
  background-color: #034A3B;
  @include mq-min(md) {
    padding: 70px 0;
  }
}

.l-footer__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.l-footer__logo {
  display: block;
  width: 100px;
  margin: auto;
  @include mq-min(md) {
    width: 147px;
    margin: 0;
  }
}

.l-footer__address {
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  margin-top: 8px;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 20px;
    text-align: left;
  }
}

.l-footer__tel-fax {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 19px;
  font-size: 1.4rem;
  color: #fff;
  @include google_font;
  margin-top: 8px;
  @include mq-min(md) {
    justify-content: flex-start;
    column-gap: 20px;
    margin-top: 20px;
  }
}

.l-footer__copy {
  display: block;
  font-size: 1rem;
  color: #DBDBDB;
  text-align: center;
  margin-top: 26px;
  @include mq-min(md) {
    width: fit-content;
    font-size: 1.2rem;
    text-align: left;
    margin-top: -22px;
    margin-left: auto;
  }
}
@use '../setting/index' as *;

.p-policy-section {
  padding: 30px 0 60px;
  @include mq-min(md) {
    padding: 80px 0 200px;
  }
}

.p-policy-section__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-policy-read {
  font-size: 1.4rem;
  @include mq-min(md) {
    max-width: 1100px;
    font-size: 1.6rem;
    margin: auto;
  }
}

.p-policy-description dt {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 30px 10px 10px 10px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  @include mq-min(md) {
    font-size: 2rem;
    padding: 80px 20px 20px 20px;
  }
}

.p-policy-description dd {
  font-size: 1.4rem;
  padding: 10px 10px 0 10px;
  @include mq-min(md) {
    font-size: 1.6rem;
    padding: 20px 50px 0 50px;
  }
}

.p-policy-description__list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  row-gap: 10px;
  margin-left: 1em;
  @include mq-min(md) {
    row-gap: 20px;
    margin-left: 2em;
  }
}

.p-policy-description__list li {
  padding-left: 2em;
  text-indent: -2.6em;
}

.p-policy-description__contact-list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  row-gap: 10px;
  @include mq-min(md) {
    margin-top: 20px;
  }
}

.p-policy-description__sub-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 10px;
  @include mq-min(md) {
    margin-top: 20px;
  }

}

.p-policy-description__sub-list li {
  padding-left: 0;
  text-indent: 0;
}
@use '../setting/index' as *;

.p-404-section {
  padding: 30px 0 60px;
  @include mq-min(md) {
    padding: 100px 0 200px;
  }
}

.p-404-section__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-404-text {
  font-size: 1.6rem;
  padding-bottom: 20px;
  border-bottom: 1px solid #A7A7A7;
  @include mq-min(md) {
    text-align: center;
    padding-bottom: 50px;
  }
}

.p-404-button-wrap {
  width: min(615px, 82vw);
  padding: 30px 15px 0 0;
  margin: auto;
  @include mq-min(md) {
    width: 283px;
  }
}
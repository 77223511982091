@use '../setting/index' as *;

.p-main {
  @include mq-min(md) {
    position: relative;
  }
}

.p-main::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 31.27%;
    background: url(/assets/images/top/mv_bg_pc.jpg) no-repeat center / cover;
    z-index: -2;
  }
}

.p-main::after {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 14%;
    left: 0;
    right: 0;
    padding-top: 31.27%;
    background: url(/assets/images/top/main_after_bg.png) no-repeat center / cover;
    z-index: -2;
  }
}

html[lang=en] .p-main::after,
html[lang=ko] .p-main::after,
html[lang=zh-CN] .p-main::after,
html[lang=zh-tw] .p-main::after {
  @include mq-min(md) {
    top: 8.5%;
  }
}
@use '../setting/index' as *;

.p-access-section {
  padding: 30px 0 60px;
  @include mq-min(md) {
    padding: 80px 0 200px;
  }
}

.p-access-section__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-access-hotel-detail {
  @include mq-min(md) {
    display: flex;
    align-items: center;
    column-gap: 9.167%;
  }
}

.p-access-hotel-detail__text-area {

}

.p-access-hotel-detail__description {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  margin-top: 10px;
  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-access-hotel-detail__description dt {
  width: 34.921%;
  font-weight: bold;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  padding: 10px 5px;
}

.p-access-hotel-detail__description dd {
  width: 65.079%;
  border-bottom: 2px solid #DBDBDB;
  padding: 10px 5px 10px 1em;

}

.p-access-hotel-detail__image {
  display: block;
  margin-top: 20px;
}

.p-access-google-map {
  margin-top: 30px;
  @include mq-min(md) {
    margin-top: 74px;
  }
}

.p-access-google-map__iframe-wrap {
  position: relative;
  width: 100%;
  padding-top: 79.365%;
  margin-top: 20px;
  @include mq-min(md) {
    padding-top: 33.334%;
  }
}

.p-access-google-map__iframe-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-access-way {
  margin-top: 30px;
  padding-bottom: 30px;
  @include mq-min(md) {
    margin-top: 80px;
  }
}

.p-access-way__content {
  margin-top: 60px;
}

.p-access-way__sub-heading {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #A7A7A7;
  @include mq-min(md) {
    font-size: 2rem;
  }
}

.p-access-way__image {
  display: block;
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 30px;
  }
}

.p-access-way__image img {
  width: 100%;
}

.p-access-way__note {
  font-size: 1.6rem;
  margin-top: 30px;
  @include mq-min(md) {
    max-width: 590px;
    font-size: 1.8rem;
    margin: 70px auto 0;
  }
}

.p-access-way__link-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
  @include mq-min(md) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 992px;
    margin: 20px auto 0;
  }
}

.p-access-way__link {
  display: grid;
  place-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  min-height: 48px;
  padding: 13px 15px;
  border: 1px solid #707070;
  @include mq-min(md) {
    width: calc((100% - 20px) / 2);
    font-size: 1.6rem;
    min-height: 80px;
    transition: background-color .3s;
  }
}

.p-access-way__link:hover {
  @include mq-min(md) {
    background-color: #D1E3E0;
  }
}

.p-access-way__link span {
  position: relative;
}

.p-access-way__link span::before {
  content: '';
  position: absolute;
  top: 48%;
  right: -20px;
  transform: translateY(-50%);
  width: 14px;
  height: 12px;
  background: url(/assets/images/access/icon_blank.svg) no-repeat center / cover;
}

.p-access-parking {
  width: calc(100% + 60px);
  padding: 30px 0;
  background: linear-gradient(135deg, #f1ece3 0%, #eaf4ef 100%);
  border: 1px solid #fff;
  margin: 0 -30px;
  @include mq-min(md) {
    width: 100vw;
    padding: 70px 0;
    margin: 0 calc(50% - 50vw);
    border: 2px solid #fff;
  }
}

.p-access-parking__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-access-parking__pc-grid {
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 0.925fr 1.075fr;
    grid-template-rows: auto;
    grid-column-gap: 80px;
    grid-row-gap: 0px;
    margin-top: 50px;
  }
}

.p-access-parking__description {
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 2;
  }
}
.p-access-parking__figure-wrap {
  @include mq-min(md) {
    grid-area: 2 / 1 / 3 / 2;
  }
}
.p-access-parking__map {
  @include mq-min(md) {
    grid-area: 1 / 2 / 3 / 3;
  }
}

.p-access-parking__description {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  font-size: 1.4rem;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-top: 0;
  }
}

.p-access-parking__description dt {
  width: 34.921%;
  font-weight: 500;
  padding: 10px 5px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  @include mq-min(md) {
    width: 29%;
    padding: 20px 10px;
  }
}

.p-access-parking__description dd {
  width: 65.079%;
  padding: 10px 5px 10px 1em;
  border-bottom: 2px solid #DBDBDB;
  @include mq-min(md) {
    width: 71%;
    padding: 20px 10px;
  }
}

.p-access-parking__figure-wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 15px;
  margin-top: 30px;
  @include mq-min(md) {
    gap: 20px;
  }
}

.p-access-parking__figure:first-of-type {
  width: 100%;
}

.p-access-parking__figure:nth-of-type(2),
.p-access-parking__figure:nth-of-type(3) {
  width: calc((100% - 15px) / 2);
  @include mq-min(md) {
    width: calc((100% - 20px) / 2);
  }
}

.p-access-parking__figure figcaption {
  font-size: 1.4rem;
  margin-top: 10px;
  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-access-parking__map {
  display: block;
  margin-top: 20px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-access-rental {
  padding-top: 30px;
  @include mq-min(md) {
    padding-top: 70px;
  }
}

.p-access-rental__pc-grid {
  @include mq-min(md) {
    display: grid;
    grid-template-columns: 1.11fr 0.89fr;
    grid-template-rows: auto;
    grid-column-gap: 66px;
    margin-top: 30px;
  }
}

.p-access-rental__images-text-set {
  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 3;
  }
}
.p-access-rental__link {
  @include mq-min(md) {
    grid-area: 2 / 2 / 3 / 3;
  }
}
.p-access-rental__main-image {
  @include mq-min(md) {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.p-access-rental__images {
  display: flex;
  column-gap: 15px;
  margin-top: 30px;
  @include mq-min(md) {
    column-gap: 5px;
  }
}

.p-access-rental__image {
  width: calc((100% - 15px) / 2);
}

.p-access-rental__text {
  font-size: 1.6rem;
  margin-top: 20px;
}

.p-access-rental__text:first-of-type {
  margin-top: 30px;
  @include mq-min(md) {
    margin-top: 15px;
  }
}

.p-access-rental__link {
  display: grid;
  place-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  min-height: 48px;
  padding: 13px 15px;
  margin-top: 20px;
  border: 1px solid #707070;
  @include mq-min(md) {
    transition: background-color .3s;
  }
}

.p-access-rental__link:hover {
  @include mq-min(md) {
    background-color: #D1E3E0;
  }
}

.p-access-rental__link span {
  position: relative;
}

.p-access-rental__link span::before {
  content: '';
  position: absolute;
  top: 48%;
  right: -20px;
  transform: translateY(-50%);
  width: 14px;
  height: 12px;
  background: url(/assets/images/access/icon_blank.svg) no-repeat center / cover;
}

.p-access-rental__main-image {
  display: block;
  margin-top: 30px;
}
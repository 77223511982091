@use '../setting/index' as *;

.p-measures-section {
  padding: 30px 0 60px;
  @include mq-min(md) {
    padding-top: 80px;
    padding-bottom: 11.719vw;
  }
}

.p-measures-section__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-measures-read {
  font-size: 1.6rem;
  @include mq-min(md) {
    text-align: center;
  }
}

.p-measures-media-set {
  @include mq-min(md) {
    display: flex;
    padding-right: 40px;
    border-bottom: 2px solid;
    border-image: $accent_color_gradation;
    border-image-slice: 1;
  }
}

.p-measures-media-set:first-of-type {
  margin-top: 10px;
  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-measures-media-set:last-of-type {
  @include mq-min(md) {
    border-bottom: none;
  }
}

.p-measures-media {
  display: flex;
  column-gap: 20px;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  @include mq-min(md) {
    align-items: center;
    column-gap: 5.489%;
    width: min(530px, 45.723%);
    border-bottom: none;
    padding-top: min(50px, 3.90625vw);
    padding-bottom: min(50px, 3.90625vw);
  }
}

.p-measures-media:nth-of-type(odd) {
  @include mq-min(md) {
    margin-right: 10px;
  }
}

.p-measures-media:nth-of-type(even) {
  @include mq-min(md) {
    margin-left: auto;
  }
}

.p-measures-media__image {
  width: min(160px, 21.333vw);
  @include mq-min(md) {
    width: min(150px, 32.751%);
  }
}

.p-measures-media__text-area {
  flex: 1;
  @include mq-min(md) {
    flex: initial;
    width: min(350px, 79.727%);
  }
}

.p-measures-media__heading {
  font-size: 1.6rem;
  font-weight: bold;
}

.p-measures-media__list {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: 1.4rem;
  margin-top: 10px;
  @include mq-min(md) {
    font-size: clamp(1.4rem, 1.25vw,1.6rem);
  }
}

.p-measures-media__list li {
  padding-left: 1em;
  text-indent: -1em;
}
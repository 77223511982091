@use "../setting/index" as *;

.p-sightseeing-anchor {
  padding: 30px 0;

  @include mq-min(md) {
    padding: 80px 0 70px;
  }
}

.p-sightseeing-anchor__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-sightseeing-anchor__heading {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #a7a7a7;

  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-sightseeing-anchor__heading-accent {
  display: block;
  font-size: 3rem;
  @include google_font;

  @include mq-min(md) {
    display: inline-block;
    margin-right: 20px;
  }
}

.p-sightseeing-anchor__heading-accent::first-letter {
  color: $accent_color_green;
}

.p-sightseeing-anchor__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;

  @include mq-min(md) {
    flex-direction: row;
    column-gap: 2.648%;
    padding: 0 2.913%;
    margin-top: 50px;
  }
}

.p-sightseeing-anchor__list li {
  @include mq-min(md) {
    width: 31.598%;
  }
}

.p-sightseeing-anchor__item {
  display: flex;
  width: 100%;
  border: 1px solid #a7a7a7;

  @include mq-min(md) {
    transition: opacity 0.3s;
  }
}

.p-sightseeing-anchor__item:hover {
  @include mq-min(md) {
    opacity: 0.7;
  }
}

.p-sightseeing-anchor__image {
  display: block;
  width: 35.56%;

  @include mq-min(md) {
    width: 35.754%;
  }
}

.p-sightseeing-anchor__image img {
  @include mq-min(md) {
    width: 100%;
    max-width: auto;
    height: 100%;
    object-fit: cover;
  }
}

.p-sightseeing-anchor__text {
  flex: 1;
  display: grid;
  place-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0.4em;
}

.p-sightseeing-attractions {
  padding: 30px 0 100px;
  background-color: #333;

  @include mq-min(md) {
    padding: 70px 0 130px;
    background-image: url(/assets/images/sightseeing/bg_title.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 1920px 230px;
    overflow: hidden;
  }
}

.p-sightseeing-attractions__inner {
  padding: 0 15px;

  @include mq-min(md) {
    max-width: 1460px;
    padding-left: min(220px, 14.667vw);
    padding-right: min(220px, 14.667vw);
    margin: auto;
  }
}

.p-sightseeing-attractions__heading {
  position: relative;
  width: fit-content;
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  padding: 0 15px;
  margin: auto;
  text-align: center;
  text-shadow: 0px 0px 10px #feff00;

  @include mq-min(md) {
    font-size: 3rem;
    font-weight: 500;
    text-shadow: 0px 0px 20px #feff00;
  }
}

.p-sightseeing-attractions__heading-accent {
  display: block;
  font-size: 3rem;
  @include google_font;
  text-shadow: 0px 0px 10px #00ffcb;

  @include mq-min(md) {
    font-size: 4rem;
    text-shadow: 0px 0px 20px #00ffcb;
  }
}

.p-sightseeing-attractions__heading-accent::first-letter {
  color: #59ccb5;
}

.p-sightseeing-attractions__heading::before {
  content: "";
  position: absolute;
  top: 26px;
  left: -45px;
  width: 65px;
  height: 65px;
  background: url(/assets/images/sightseeing/title_left.svg) no-repeat center / cover;

  @include mq-min(lg) {
    top: 0;
    left: -125px;
    width: 130px;
    height: 130px;
  }

  @include mq-min(md) {
    top: 0;
    left: -75px;
    width: 100px;
    height: 100px;
  }
}

.p-sightseeing-attractions__heading::after {
  content: "";
  position: absolute;
  top: 30px;
  right: -44px;
  width: 65px;
  height: 65px;
  background: url(/assets/images/sightseeing/title_right.svg) no-repeat center / cover;

  @include mq-min(lg) {
    top: 0;
    right: -125px;
    width: 130px;
    height: 130px;
  }

  @include mq-min(md) {
    top: 0;
    right: -75px;
    width: 100px;
    height: 100px;
  }
}

.p-sightseeing-check-map {
  position: relative;
  padding: 10px 15px 20px;
  margin-top: 30px;
  border: 2px solid #fff;
  border-radius: 10px;
  box-shadow:
    0px 0px 5px #e19ba9,
    inset 0px 0px 5px #e19ba9;

  @include mq-min(md) {
    max-width: 1170px;
    width: calc(100% + 150px);
    margin: 130px -75px 0;
    padding: 60px 5.889% 50px 8.343%;
    border: 3px solid #fff;
    border-radius: 20px;
  }
}

.p-sightseeing-check-map::before {
  @include mq-min(md) {
    content: "";
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    border: 3px solid #fff;
    border-radius: 20px;
    box-shadow:
      0px 0px 5px #e19ba9,
      inset 0px 0px 5px #e19ba9;
  }
}

.p-sightseeing-check-map__pc-2col {
  @include mq-min(lg) {
    display: flex;
  }

  @include mq-min(md) {
    position: relative;
    z-index: 5;
  }
}

.p-sightseeing-check-map__text-area {
  @include mq-min(lg) {
    width: 38.904%;
  }
}

.p-sightseeing-check-map__heading {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 10px #ff9191;
  padding: 0 10px;
  background-color: #333;

  @include mq-min(md) {
    top: -43px;
    left: 40px;
    transform: none;
    font-size: 2rem;
    text-shadow: 0px 0px 20px #ff9191;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.p-sightseeing-check-map__heading-accent {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  @include google_font;
  text-shadow: 0px 0px 10px #ff9191;
  margin-right: 10px;
  padding-left: 45px;

  @include mq-min(md) {
    font-size: 4rem;
    padding-left: 75px;
    text-shadow: 0px 0px 20px #ff9191;
  }
}

.p-sightseeing-check-map__heading-accent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 25px;
  background: url(/assets/images/sightseeing/star.svg) no-repeat center / cover;

  @include mq-min(md) {
    width: 55px;
    height: 35px;
    top: 15px;
  }
}

.p-sightseeing-check-map__text {
  font-size: 1.6rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-top: 20px;

  @include mq-min(md) {
    font-size: 1.8rem;
    white-space: nowrap;
    margin-top: 0;
  }
}

.p-sightseeing-check-map__text span {
  color: #ecee9b;
}

.p-sightseeing-check-map__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 10px;

  @include mq-min(lg) {
    flex-direction: column;
  }

  @include mq-min(md) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

.p-sightseeing-check-map__list-item {
  font-size: 1.4rem;
  color: #fff;
  padding: 5px 10px 5px 32px;
  border-radius: 5px;
  background-image: url(/assets/images/sightseeing/flag.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px;

  @include mq-min(lg) {
    width: 100%;
  }

  @include mq-min(md) {
    width: 50%;
    padding: 4px 20px 4px 38px;
    background-size: 20px;
    border-radius: 10px;
    transition: background-color 0.2s;
    cursor: pointer;
  }
}

.p-sightseeing-check-map__list-item:hover {
  @include mq-min(md) {
    background-color: rgba($color: #ff9191, $alpha: 0.5);
  }
}

.is-active.p-sightseeing-check-map__list-item {
  background-color: rgba($color: #ff9191, $alpha: 0.5);
}

.p-sightseeing-check-map__iframe {
  position: relative;
  height: 0;
  padding-top: 66.667%;
  margin-top: 15px;
  overflow: hidden;

  @include mq-min(lg) {
    width: 100%;
    max-width: 620px;
    width: 71.363%;
    padding-top: 43.18%;
    margin-left: 6.478%;
    margin-top: 0;
  }
}

.p-sightseeing-check-map__iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-sightseeing-check-map__google-map-wrap {
  position: relative;
  height: 0;
  padding-top: 66.667%;
  margin-top: 15px;
  overflow: hidden;

  @include mq-min(lg) {
    width: 100%;
    max-width: 620px;
    width: 71.363%;
    padding-top: 43.18%;
    margin-left: 6.478%;
    margin-top: 0;
  }
}

.p-sightseeing-check-map__google-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-sightseeing-check-map__google-map .gmnoprint {
  display: none !important;
}

.p-sightseeing-check-map__spot-name {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-sightseeing-recommend {
  position: relative;
  padding: 50px 37px 59px;
  background: url(/assets/images/sightseeing/bg_dot_sp.png) no-repeat bottom center / 100%;
  border-radius: 10px;
  border: 2px solid #fff;
  box-shadow:
    0px 0px 5px #00ffcb,
    inset 0px 0px 5px #00ffcb;
  margin-top: 100px;

  @include mq-min(md) {
    max-width: 1000px;
    margin: 135px auto 0;
    border-radius: 20px;
    border: 3px solid #fff;
    box-shadow:
      0px 0px 10px #00ffcb,
      inset 0px 0px 10px #00ffcb;
    background: url(/assets/images/sightseeing/bg_dot_pc.png) no-repeat bottom center / 100%;
  }
}

%recommend_num {
  position: absolute;
  width: 112px;

  @include mq-min(md) {
    width: min(224px, 15.379vw);
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.p-sightseeing-recommend__num-left {
  @extend %recommend_num;
  top: -56px;
  left: -8px;

  @include mq-min(md) {
    top: max(-111px, -7.655vw);
    left: max(-111px, -7.655vw);
  }
}

.p-sightseeing-recommend__num-right {
  @extend %recommend_num;
  top: -56px;
  right: -8px;

  @include mq-min(md) {
    top: max(-111px, -7.655vw);
    right: max(-111px, -7.655vw);
  }
}

%sightseeing-recommend__heading {
  position: relative;
  width: fit-content;
  font-size: 2rem;
  color: #fff;
  text-shadow: 0px 0px 10px #fff;

  @include mq-min(md) {
    font-size: 3rem;
    text-shadow: 0px 0px 20px #fff;
  }
}

.p-sightseeing-recommend__heading--center {
  @extend %sightseeing-recommend__heading;
  text-align: center;
  margin: auto;
}

.p-sightseeing-recommend__heading--center::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  width: 27px;
  height: 37px;
  background: url(/assets/images/sightseeing/tree.svg) no-repeat center / cover;

  @include mq-min(md) {
    left: -60px;
    width: 54px;
    height: 74px;
  }
}

.p-sightseeing-recommend__heading--center::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%) scale(-1, 1);
  width: 27px;
  height: 37px;
  background: url(/assets/images/sightseeing/tree.svg) no-repeat center / cover;

  @include mq-min(md) {
    right: -60px;
    width: 54px;
    height: 74px;
  }
}

.p-sightseeing-recommend__heading--left {
  @extend %sightseeing-recommend__heading;
}

.p-sightseeing-recommend__heading--left::after {
  content: "";
  position: absolute;
  bottom: 6px;
  right: -30px;
  transform: scale(-1, 1);
  width: 27px;
  height: 37px;
  background: url(/assets/images/sightseeing/tree.svg) no-repeat center / cover;

  @include mq-min(md) {
    right: -60px;
    width: 54px;
    height: 74px;
  }
}

.p-sightseeing-recommend__heading--right {
  @extend %sightseeing-recommend__heading;
  margin-left: auto;
  margin-right: 37px;

  @include mq-min(md) {
    margin-left: 0;
  }
}

.p-sightseeing-recommend__heading--right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%) scale(-1, 1);
  width: 27px;
  height: 37px;
  background: url(/assets/images/sightseeing/tree.svg) no-repeat center / cover;

  @include mq-min(md) {
    right: -60px;
    width: 54px;
    height: 74px;
  }
}

.p-sightseeing-recommend__heading-en {
  display: block;
  font-size: 1.2rem;
  @include google_font;

  @include mq-min(md) {
    font-size: 1.4rem;
  }
}

.p-sightseeing-recommend__text {
  font-size: 1.6rem;
  color: #fff;
  margin-top: 20px;

  @include mq-min(md) {
    margin: 20px auto 0;
  }
}

.p-sightseeing-recommend__text--center {
  font-size: 1.6rem;
  color: #fff;
  margin-top: 20px;

  @include mq-min(md) {
    max-width: 600px;
    text-align: center;
    margin: 20px auto 0;
  }
}

%text-and-border {
  position: relative;
  font-size: 1.4rem;
  color: #fff;
  padding-top: 20px;
  margin-top: 20px;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

%text-and-border-before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 1px dotted #dbdbdb;
}

.p-sightseeing-recommend__text--medium {
  font-size: 1.6rem;

  @include mq-min(md) {
    font-size: 2.2rem;
  }
}

.p-sightseeing-recommend__text--large {
  font-size: 1.8rem;
  color: #ecee9b;

  @include mq-min(md) {
    font-size: 3rem;
  }
}

.p-sightseeing-recommend__text-and-border--center {
  @extend %text-and-border;
  text-align: center;
}

.p-sightseeing-recommend__text-and-border--center::before {
  @extend %text-and-border-before;

  @include mq-min(md) {
    max-width: 400px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.p-sightseeing-recommend__text-and-border {
  @extend %text-and-border;
}

.p-sightseeing-recommend__text-and-border::before {
  @extend %text-and-border-before;
}

.p-sightseeing-recommend__text-and-border--right {
  @extend %text-and-border;

  @include mq-min(lg) {
    text-align: right;
  }
}

.p-sightseeing-recommend__text-and-border--right::before {
  @extend %text-and-border-before;
}

.p-sightseeing-recommend__2col-image {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 50px;
  padding: 0 10px;

  @include mq-min(md) {
    flex-direction: row;
    column-gap: 20px;
    width: calc(100% + 280px);
    padding: 0;
    margin: 30px -140px 0;
  }
}

.p-sightseeing-recommend__2col-image-item {
  position: relative;
}

%chara {
  position: absolute;
  width: min(134px, 50%);

  @include mq-min(md) {
    width: min(250px, 17.241vw);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.p-sightseeing-recommend__chara01 {
  @extend %chara;
  top: -40px;
  right: -38px;

  @include mq-min(md) {
    top: max(-200px, -13.793vw);
    right: max(-10px, 0.69vw);
  }
}

.p-sightseeing-recommend__chara02 {
  @extend %chara;
  bottom: -20px;
  left: -25px;

  @include mq-min(md) {
    bottom: max(-100px, -6.897vw);
    left: max(-100px, -2vw);
  }
}

.p-sightseeing-recommend__chara03 {
  @extend %chara;
  bottom: -20px;
  right: -25px;

  @include mq-min(lg) {
    bottom: max(-100px, -6.623vw);
    right: max(-625px, -43.103vw);
  }
}

.p-sightseeing-recommend__chara04 {
  @extend %chara;
  top: -40px;
  right: -25px;
}

.p-sightseeing-recommend__chara05 {
  @extend %chara;
  bottom: -20px;
  left: -25px;
}

.p-sightseeing-recommend__chara06 {
  @extend %chara;
  top: -50px;
  left: -25px;

  @include mq-min(md) {
    top: max(-120px, -8.276vw);
    left: max(-100px, -2vw);
  }
}

.p-sightseeing-recommend__chara07 {
  @extend %chara;
  bottom: -20px;
  right: -25px;

  @include mq-min(md) {
    right: max(-100px, -2vw);
  }
}

.p-sightseeing-recommend__chara08 {
  @extend %chara;
  bottom: -20px;
  left: -25px;

  @include mq-min(lg) {
    bottom: max(-100px, -6.623vw);
    left: max(-625px, -43.103vw);
  }
}

.p-sightseeing-recommend__chara09 {
  @extend %chara;
  bottom: -20px;
  right: -25px;

  @include mq-min(lg) {
    bottom: max(-100px, -6.623vw);
    right: max(-625px, -46.103vw);
  }
}

.p-sightseeing-recommend__chara10 {
  @extend %chara;
  bottom: -20px;
  left: -25px;

  @include mq-min(lg) {
    bottom: max(-100px, -6.623vw);
    left: max(-625px, -45.103vw);
  }
}

.p-sightseeing-recommend__chara11 {
  @extend %chara;
  bottom: -20px;
  right: -25px;

  @include mq-min(lg) {
    bottom: max(-100px, -6.623vw);
    right: max(-625px, -43.103vw);
  }
}

.p-sightseeing-recommend__chara12 {
  @extend %chara;
  bottom: -20px;
  left: -25px;

  @include mq-min(lg) {
    bottom: max(-100px, -6.623vw);
    left: max(-625px, -43.103vw);
  }
}

.p-sightseeing-recommend__chara13 {
  @extend %chara;
  bottom: -20px;
  right: -25px;

  @include mq-min(lg) {
    bottom: max(-100px, -6.623vw);
    right: max(-625px, -43.103vw);
  }
}

.p-sightseeing-recommend__button {
  position: absolute;
  bottom: -31px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  place-content: center;
  width: fit-content;
  min-height: 58px;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  padding: 0 20px;
  border-radius: 29px;
  border: 2px solid #fff;
  background-color: #333;
  box-shadow:
    0px 0px 5px #00ffcb,
    inset 0px 0px 5px #00ffcb;

  @include mq-min(md) {
    font-size: 1.6rem;
    padding: 0 30px;
    box-shadow:
      0px 0px 10px #00ffcb,
      inset 0px 0px 10px #00ffcb;
  }
}

.p-sightseeing-recommend__button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: 29px;
  z-index: -1;

  @include mq-min(md) {
    background-color: transparent;
    background-blend-mode: lighten;
    transition: background-color 0.3s;
  }
}

.p-sightseeing-recommend__button:hover::before {
  @include mq-min(md) {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.p-sightseeing-recommend__button span {
  position: relative;
  z-index: 5;
}

.p-sightseeing-recommend__button span::before {
  content: "";
  position: absolute;
  top: 48%;
  right: -8px;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 6px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.p-sightseeing-recommend__text-image-set {
  @include mq-min(lg) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 50px;
    width: calc(100% + 80px);
    margin-left: 60px;
    margin-right: -120px;
  }

  @include mq-min(md) {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
  }
}

.p-sightseeing-recommend__text-image-set--reverse {
  @include mq-min(lg) {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    column-gap: 50px;
    width: calc(100% + 80px);
    margin-left: -120px;
  }

  @include mq-min(md) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    width: 100%;
  }
}

.p-sightseeing-recommend__text-area {
  @include mq-min(lg) {
    width: 36%;
  }

  @include mq-min(md) {
    width: 100%;
  }
}

.p-sightseeing-recommend__image {
  position: relative;
  margin-top: 20px;
  padding: 0 10px;

  @include mq-min(lg) {
    width: 59%;
  }

  @include mq-min(md) {
    width: 90%;
    padding: 0;
    margin-top: 0;
  }
}

.p-sightseeing-restaurant {
  padding: 60px 0;
  background: linear-gradient(#f1ece3 0%, #eaf4ef 100%);

  @include mq-min(md) {
    padding: 95px 0 70px;
  }
}

.p-sightseeing-restaurant__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-sightseeing-restaurant__text {
  font-size: 1.6rem;
  margin-top: 30px;

  @include mq-min(md) {
    max-width: 750px;
    margin: 50px auto 0;
    text-align: center;
  }
}

.p-sightseeing-restaurant__sub-contents {
  margin-top: 40px;
}

.p-sightseeing-restaurant__sub-heading {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #a7a7a7;

  @include mq-min(md) {
    font-size: 2rem;
  }
}

.p-sightseeing-restaurant__map {
  display: block;
  margin-top: 20px;
}

.p-sightseeing-restaurant__map img {
  width: 100%;
}

.p-sightseeing-button-wrap {
  max-width: 630px;
  padding-right: 15px;
  margin: 20px auto 0;

  @include mq-min(md) {
    max-width: 283px;
    padding-right: 0;
    margin: 30px auto 0;
  }
}

.p-sightseeing-stay {
  padding: 60px 0;

  @include mq-min(md) {
    padding: 70px 0 200px;
  }
}

.p-sightseeing-stay__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-sightseeing-stay__read {
  font-size: 1.6rem;
  margin-top: 30px;

  @include mq-min(md) {
    text-align: center;
    margin-top: 50px;
  }
}

.p-sightseeing-stay__card-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 22px;

  @include mq-min(md) {
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 50px;
    column-gap: 48px;
  }
}

.p-sightseeing-stay__card {
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;

  @include mq-min(md) {
    width: calc((100% - 48px) / 2);
  }
}
.p-sightseeing-stay__card.p-sightseeing-stay__card__last {
  position: relative;
  padding-bottom: 0;
  border-bottom: none;
}
.p-sightseeing-stay__card::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 40px;
  border-bottom: 3px solid #dbdbdb;
}
.p-sightseeing-stay__card.p-sightseeing-stay__card__last:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 40px;
  border-bottom: none;
}
.p-sightseeing-stay__card-image {
  display: block;
}

.p-sightseeing-stay__card-heading {
  font-size: 1.6rem;
  margin-top: 20px;

  @include mq-min(md) {
    font-size: 2.4rem;
    font-weight: 500;
  }
}

.p-sightseeing-stay__card-text {
  font-size: 1.6rem;
  margin-top: 20px;
}

.p-sightseeing-stay__card-button {
  max-width: 630px;
  padding-right: 15px;
  margin: 20px auto 0;

  @include mq-min(md) {
    max-width: 200px;
    padding-right: 0;
    margin: 20px 30px 0 auto;
  }
}

.p-sightseeing-stay__subsection {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 20px;
  margin-bottom: 50px;
  position: relative;

  @include mq-min(md) {
    padding-bottom: 40px;
    margin-bottom: 60px;
  }
}
.p-sightseeing-stay__subsection.card__last {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 20px;
  margin-bottom: 0;
  position: relative;
}
.p-sightseeing-stay__subsection::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 40px;
  border-bottom: 3px solid #dbdbdb;
}

@use '../setting/index' as *;

.p-parallax-bg-right {
  display: none;
  @include mq-min(md) {
    display: block;
    position: absolute;
    right: 0;
    width: 9vw;
    opacity: .5;
    z-index: 2;
  }
}

.p-parallax-bg-left {
  display: none;
  @include mq-min(md) {
    display: block;
    position: absolute;
    left: 0;
    width: 9vw;
    opacity: .5;
    z-index: 2;
  }
}
@use '../setting/index' as *;
@use '../../../node_modules/swiper/swiper-bundle.min.css';

// 客室ページのみで使いまわすheading ↓↓
.p-guestroom-heading {
  width: fit-content;
  font-size: 1.8rem;
  text-align: center;
  margin: auto;

  @include mq-min(md) {
    font-size: 2rem;
  }
}

.p-guestroom-heading2 {
  width: fit-content;
  font-size: 1.8rem;
  text-align: center;
  margin: auto;

  @include mq-min(md) {
    font-size: 2rem;
  }
}

%top-heading-accent {
  position: relative;
  display: block;
  font-size: 4rem;
  @include google_font;
  line-height: 1.15;

  @include mq-min(md) {
    font-size: 7rem;
  }
}

// 客室ページのみで使いまわすheading ↑↑

// 客室詳細　客室一覧ページ ↓↓
.p-guestroom-section {
  padding: 30px 0 60px;

  @include mq-min(md) {
    padding: 80px 0 200px;
  }
}

.p-guestroom-section__inner {
  padding: 0 30px;

  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-guestroom-contents-wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 26px;
  row-gap: 30px;
  margin-top: 20px;

  @include mq-min(md) {
    column-gap: 48px;
    row-gap: 80px;
    margin-top: 80px;
  }
}

.p-guestroom-content {
  position: relative;
  width: calc((100% - 26px) / 2);
  padding-bottom: 10px;
  border-bottom: 1px solid #DBDBDB;

  @include mq-min(md) {
    width: calc((100% - 48px) / 2);
    padding-bottom: 20px;
    border-bottom: 2px solid #DBDBDB;
  }
}

.p-guestroom-content__image img {
  width: 100%;
}

.p-guestroom-content::before {
  content: '';
  position: absolute;
  bottom: -2px;
  width: min(80px, 10.667vw);
  height: 3px;
  background-color: #DBDBDB;

  @include mq-min(md) {
    bottom: -3px;
    height: 5px;
    width: 80px;
  }
}

.p-guestroom-content__heading {
  font-size: 2.2rem;
  @include google_font;
  color: #034a3b;
  margin-top: 10px;

  @include mq-min(md) {
    font-size: 4rem;
    margin-top: 20px;
  }
}

.p-guestroom-content__heading span {
  display: block;
  font-size: 1.2rem;
  color: #a7a7a7;

  @include mq-min(md) {
    margin-bottom: 8px;
  }
}

.p-guestroom-content__text {
  font-size: 1.4rem;
  margin-top: 10px;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-guestroom-content__list-button-set {
  @include mq-min(md) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
  }
}

.p-guestroom-content__list {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  font-size: 1.4rem;
  margin-top: 10px;

  @include mq-min(md) {
    row-gap: 10px;
    font-size: 1.6rem;
    margin-top: 0;
  }
}

.p-guestroom-content__button {
  padding-right: 10px;
  margin-top: 10px;

  @include mq-min(md) {
    width: 230px;
    padding-right: 30px;
  }
}

// 客室詳細　客室一覧ページ ↑↑





// 客室詳細　MV ↓↓
.p-room-lower-mv {
  position: relative;
}

.p-room-lower-mv__inner {
  max-width: 1024px;
  margin: auto;
}

.p-room-lower-mv__heading-wrap {
  padding: 22.8% 0 8%;

  @include mq-min(md) {
    padding: 160px 0 30px;
  }
}

.p-room-lower-mv__heading {
  text-align: center;
  color: #fff;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
}

.p-room-lower-mv__heading--en {
  font-size: 3rem;
  @include google_font;
  text-decoration: underline;
  text-decoration-thickness: from-font;

  @include mq-min(md) {
    font-size: 6rem;
  }
}

.p-room-lower-mv__heading--jp {
  font-size: 1.6rem;
  font-weight: bold;
  display: block;
}

.p-room-lower-mv__slide-wrap .swiper-slide img {
  width: 100%;
}

.p-room-lower-mv__slide-wrap .swiper-button-next,
.p-room-lower-mv__slide-wrap .swiper-button-prev {
  color: #333;
  width: 16px;
  height: 40px;

  @include mq-min(md) {
    width: 32px;
    height: 80px;
  }
}

.p-room-lower-mv__slide-wrap .swiper-button-next:after,
.p-room-lower-mv__slide-wrap .swiper-button-prev:after {
  background: rgba(255, 255, 255, .5);
  font-size: 1.4rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq-min(md) {
    font-size: 2.8rem;
  }
}

.p-room-lower-mv__slide-wrap .swiper-button-next {
  right: 0;
}

.p-room-lower-mv__slide-wrap .swiper-button-prev {
  left: 0;
}

.p-room-lower-mv__slide-thumbnail {
  padding-top: 10px;
  background: #fff;
}

.p-room-lower-mv__slide-thumbnail .swiper-wrapper {
  justify-content: center;

  @include mq-min(md) {
    justify-content: flex-end;
  }
}

.p-room-lower-mv__slide-thumbnail .swiper-slide {
  max-width: 58px;
  opacity: .3;
  transition: opacity .3s;

  @include mq-min(md) {
    max-width: 105px;
    cursor: pointer;
  }

  &:hover {
    @include mq-min(md) {
      opacity: 1;
    }
  }
}

.p-room-lower-mv__slide-thumbnail .swiper-slide.swiper-slide-thumb-active {
  opacity: 1;
}

.p-room-lower-mv__slide-thumbnail .swiper-slide:last-child {
  margin-right: 0 !important;
}

%p-room-lower-mv {
  content: "";
  position: absolute;
  width: 100%;
  height: 450px;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;

  @include mq-min(md) {
    height: 100%;
  }

  @include mq-min(lg) {
    background-size: contain;
  }
}


// 客室ごとのMV背景画像指定
.p-room-lower-mv.single::before {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/single/mv_bg_sp.jpg);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/single/mv_bg_pc.jpg);
  }
}

.p-room-lower-mv.semi-double::before {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/semi-double/mv_bg_sp.jpg);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/semi-double/mv_bg_pc.jpg);
  }
}

.p-room-lower-mv.twin::before {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/twin/mv_bg_sp.jpg);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/twin/mv_bg_pc.jpg);
  }
}

.p-room-lower-mv.corner-twin::before {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/corner-twin/mv_bg_sp.jpg);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/corner-twin/mv_bg_pc.jpg);
  }
}

.p-room-lower-mv.annex::before {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/annex/mv_bg_sp.jpg);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/annex/mv_bg_pc.jpg);
  }
}

.p-room-lower-mv.barrier-free::before {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/barrier-free/mv_bg_sp.jpg);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/barrier-free/mv_bg_pc.jpg);
  }
}

.p-room-lower-mv.phiten-room::before {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/phiten/mv_bg_sp.jpg);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/phiten/mv_bg_pc.jpg);
  }
}

// 緑の背景画像　共通
.p-room-lower-mv::after {
  @extend %p-room-lower-mv;
  background-image: url(/assets/images/guestroom/common/mv_bg_sp.png);

  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/common/mv_bg_pc.png);
    background-size: cover;
  }

  @include mq-min(lg) {
    background-size: contain;
  }
}



// .p-room-lower-mv.single::before {
//   @extend %p-room-lower-mv;
//   background-image: url(/assets/images/guestroom/single/mv_bg_sp.png);
//   @include mq-min(md) {
//     background-image: url(/assets/images/guestroom/single/mv_bg_pc.png);
//   }
// }

// .p-room-lower-mv.single::before {
//   @extend %p-room-lower-mv;
//   // height: 450px;
//   // background-image: url(/assets/images/guestroom/single/mv_bg_sp.jpg);
//   @include mq-min(md) {
//     height: 100%;
//     background-image: url(/assets/images/guestroom/single/mv_bg_pc.jpg);
//   }
// }

// .p-room-lower-mv .p-room-lower-mv__heading-wrap::before {
//   @extend %p-room-lower-mv;
//   background-image: url(/assets/images/guestroom/single/mv_bg_sp.jpg);
//   @include mq-min(md) {
//     background-image: none;
//   }
// }

// .p-room-lower-mv::after {
//   @extend %p-room-lower-mv;
//   // background-image: url(/assets/images/guestroom/common/mv_bg_sp.png);
//   @include mq-min(md) {
//     background-image: url(/assets/images/guestroom/common/mv_bg_pc.png);
//   }
// }

// .p-room-lower-mv__heading-wrap {
//   position: relative;
// }
// .p-room-lower-mv__heading-wrap::after {
//   @extend %p-room-lower-mv;
//   background-image: url(/assets/images/guestroom/common/mv_bg_sp.png);
//   @include mq-min(md) {
//     background-image: none;
//   }
// }


// 客室詳細　MV ↑↑

// 客室詳細　概要 ↓↓
.p-room-lower-detail {
  padding: 30px 0;

  @include mq-min(md) {
    padding: 49px 0 70px;
  }
}

.p-room-lower-detail__heading {
  font-size: 1.8rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include mq-min(md) {
    font-size: 2.4rem;
  }
}

.p-room-lower-detail__heading::before {
  @include mq-min(md) {
    content: "";
    width: 100%;
    height: 1px;
    background: $accent_color_gray;
    position: absolute;
  }
}

.p-room-lower-detail__heading-bg {
  @include mq-min(md) {
    background: #fff;
    padding: 0 10px;
    position: relative;
  }
}

.p-room-lower-detail__2col {
  margin-top: 20px;

  @include mq-min(md) {
    display: flex;
    align-items: flex-start;
    margin-top: 50px;
  }
}

.p-room-lower-detail__2col-image {
  border: 1px solid #DBDBDB;
  text-align: center;
  padding: 20px;
  display: block;

  @include mq-min(md) {
    width: 52.5%;
    margin-right: 5.83334%;
  }
}

.p-room-lower-detail__2col-image img {
  @include mq-min(md) {
    width: fit-content;
  }
}

.p-room-lower-detail__text {
  margin-top: 10px;

  @include mq-min(md) {
    width: calc((100% - 52.5%) - 5.83334%);
  }
}

.p-room-lower-detail__text .p-room-lower-detail__list:first-of-type {
  margin-top: 20px;
}

.p-room-lower-detail__list {
  display: flex;
}

.p-border--gradation {
  width: 34.92064%;
  font-size: 1.4rem;
  padding-left: .5em;
  padding-bottom: .5em;
  margin-bottom: .5em;
  border-bottom: 1px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;

  @include mq-min(md) {
    font-size: 1.6rem;
    padding-bottom: .8em;
    margin-bottom: .8em;
    padding-left: 1em;
  }
}

.p-border--gray {
  width: calc(100% - 34.92064%);
  font-size: 1.4rem;
  padding-left: 1em;
  padding-bottom: .5em;
  margin-bottom: .5em;
  border-bottom: 1px solid #DBDBDB;

  @include mq-min(md) {
    font-size: 1.6rem;
    padding-bottom: .8em;
    margin-bottom: .8em;
    padding-left: 2em;
  }
}

.p-room-lower-detail__note {
  margin-top: 10px;
}

.p-room-lower-detail__note-item {
  font-size: 1.4rem;
  margin-left: 1em;
  text-indent: -1em;
  margin-top: .5em;

  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

// 客室詳細　概要 ↑↑

// 客室詳細　アメニティ・設備 ↓↓
.p-room-lower-amenity-facilities {
  padding: 30px 0;
  background: transparent linear-gradient(170deg, #F1ECE3 0%, #EAF4EF 100%);

  @include mq-min(md) {
    padding: 70px 0;
  }
}

.p-room-lower-amenity__3col {
  background: #fff;
  padding: 20px 17px;
  margin-top: 26px;

  @include mq-min(md) {
    display: flex;
    gap: 20px;
    padding: 30px 8.33334%;
  }
}

.p-room-lower-amenity__3col-item:not(:first-child) {
  margin-top: 26px;

  @include mq-min(md) {
    margin-top: 0;
  }
}

.p-room-lower-amenity__3col-image {
  display: block;
  text-align: center;
}

.p-room-lower-amenity__3col-text {
  font-size: 1.6rem;
}

.p-room-lower-amenity__3col-text--en {
  display: block;
  font-size: 1.2rem;
  color: $accent_color_gray;
  @include google_font;
  margin-top: 7px;
  margin-bottom: 4px;
}

// 客室詳細　アメニティ・設備 ↑↑

// 客室詳細　その他客室 ↓↓
.p-room-lower-other-link {
  padding: 40px 0 60px;

  @include mq-min(md) {
    padding: 70px 0 200px;
  }
}

// 追加ページファイテンルーム↓↓
.phiten-room .p-room-lower-mv__heading--en {
  font-size: 3rem;
  @include google_font;
  text-decoration: underline;
  text-decoration-thickness: from-font;

  @include mq-min(md) {
    font-size: 5rem;
  }
}

.phiten-room .p-room-lower-mv__heading--small {
  @include google_font;
  font-size: 1.6rem;

  @include mq-min(md) {
    font-size: 3.6rem;
  }
}

.phiten-room-headtext {
  font-size: 1.6rem;
  width: 100%;
  margin: 0 auto;
  margin-top: min(36px, 4.86486vw);
  font-weight: 500;

  @include mq-min(md) {
    width: clamp(500px, 43.21429vw, 726px);
  }
}

.phiten-lower-detail__label {
  font-size: 1.4rem;
  color: #034A3B;
  font-weight: bold;
  padding: 5px 20px;
  background-color: #F5F5DF;
  border: solid 1px #034A3B;
  margin: 40px auto;
  width: fit-content;
}

.phiten-original-item {
  margin-top: 26px;
  padding: 70px 3.33334% 20px;
  background: transparent linear-gradient(135deg, #f9f5f1 0%, #dbe0dc 70%, #d4dddb 100%) 0% 0% no-repeat padding-box;

  @include mq-min(md) {
    max-width: 1260px;
    margin: 60px auto 0;
    padding: 70px 0 0px;
  }
}

.phiten-original-headtext {
  text-align: center;
  font-size: 1.8rem;
  margin-top: min(30px, 4.0541vw);
  font-weight: 500;

  @include mq-min(md) {
    font-size: 2rem;
    margin-top: 30px;
  }
}

.phiten-lower-amenity__3col {

  @include mq-min(md) {
    display: flex;
    justify-content: center;
    padding: 30px 0;
  }
}

.phiten-lower-amenity__3col figure {
  padding: 0 0 40px;
  border-bottom: 1px solid #A7A7A7;

  @include mq-min(md) {
    padding: 0 40px;
    border-bottom: none;
    border-right: 1px solid #A7A7A7;
    width: min(30.23816%, 380px);
  }
}

.phiten-lower-amenity__3col figure:last-of-type {
  border: none;
}

.phiten-lower-amenity__3col picture {
  margin: 20px 0 30px;
  display: block;

  @include mq-min(md) {
    margin: 0 0 10px;
  }
}

.phiten-lower-amenity__3col figcaption {
  font-size: 1.6em;
  text-indent: -1em;
  padding-left: 1em;
  font-weight: 500;

  @include mq-min(md) {}
}

.phiten-sp {
  @include mq-min(md) {
    display: none;
  }
}
@use '../setting/index' as *;

// TOPページ globalページの reservationエリア
.p-reservation {
  display: none;
  @include mq-min(md) {
    position: relative;
    display: block;
    padding: 56px 0 20px;
    z-index: 5;
  }
}

.p-reservation__inner {
  position: relative;
  z-index: 5;
  @include mq-min(md) {
    max-width: 1260px;
    padding: 0 30px;
    margin: auto;
  }
}

.p-reservation__heading {
  font-weight: bold;
  @include mq-min(md) {
    font-size: 2.4rem;
    text-align: center;
  }
}

.p-reservation__heading-accent {
  @include mq-min(md) {
    display: block;
    font-size: 5rem;
    @include google_font;
    margin-top: -9px;
  }
}

.p-reservation__heading-accent::first-letter {
  @include mq-min(md) {
    color: $accent_color_green;
  }
}

@use '../setting/index' as *;
@import '~jquery-ui/themes/base/all.css';

.ui-widget {
  font-size: 1.6rem;
}
.ui-datepicker-calendar .day-sunday > a,
.ui-datepicker-calendar .day-holiday > a {
  background: #fde;
}
.ui-datepicker-calendar .day-saturday > a {
  background: #dff;
}
.ui-icon,
.ui-widget-content .ui-icon {
	background-image: url(../images/top/ui-icons_444444_256x240.png);
}
.ui-widget-header .ui-icon {
	background-image: url(../images/top/ui-icons_444444_256x240.png);
}

.ui-datepicker-trigger {
  @include mq-min(xl) {
    top: 41px;
    right: 22px;
  }
  @include mq-min(md) {
    position: absolute;
    top: 37px;
    right: 15px;
    width: 20px;
    height: 20px;
  }
}
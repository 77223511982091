@use '../setting/index' as *;

%p-mv {
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 58px;
  font-size: 1.4rem;
  color: #fff;
  background: url(/assets/images/common/button_bg_sp.jpg) no-repeat center / cover;
  
  @include mq-min(md) {
    min-height: 80px;
    font-size: 1.6rem;
    background: url(/assets/images/common/button_bg_pc.jpg) no-repeat center / cover;
  }
}

.c-mv {
  position: relative;
}

.c-mv__inner {
  @include mq-min(md) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.c-mv__image {
  @include mq-min(md) {
    width: 50%;
    max-height: 700px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
}

.c-mv__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  @include mq-min(md) {
    width: 100%;
    height: 68vw;
    max-height: 700px;
    object-fit: cover;
  }
}

.c-mv__text {
  width: 100%;
}

.c-mv__text-wrap {
  position: relative;
  @include mq-min(md) {
    width: 50%;
  }
}

.c-mv__text-wrap::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(/assets/images/common/mv_bg_sp.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @include mq-min(md) {
    background-image: url(/assets/images/common/mv_bg_pc.png);
  }
}

.c-mv__text-wrap::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  // background-image: url(/assets/images/guestroom/mv_sp.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @include mq-min(md) {
    // background-image: url(/assets/images/guestroom/mv_pc.jpg);
  }
}

.c-mv__text-wrap-inner {
  color: #fff;
  position: relative;
  @include mq-min(md) {
    width: 50%;
    max-height: 700px;
    display: flex;
    align-items: center;
  }
}

.c-mv__text-wrap-inner .c-mv__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.c-mv__text-wrap-inner {
  position: relative;
  padding: 30px;
  @include mq-min(lg) {
    padding: 30px 0 30px 30px;
  }
  @include mq-min(md) {
    max-width: 630px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    padding: 60px 0 30px 30px;
  }
}

.c-mv__text {
  position: relative;
}

.c-mv__heading {
  color: #fff;
  border-bottom: 1px solid #fff;
  margin-bottom: 1.5em;
  @include mq-min(md) {
    padding-bottom: 2em;
  }
}

.c-mv__heading--jp {
  font-size: 1.8rem;
  @include mq-min(md) {
    font-weight: bold;
  }
}

.c-mv__heading--en {
  display: block;
  font-size: 4rem;
  @include google_font;
  line-height: 1.5;
  &::first-letter {
    color: $accent_color_yellow;
  }
  @include mq-min(md) {
    font-size: 8rem;
    line-height: 1.2;
  }
}

.c-mv__heading--en-small {
  display: block;
  font-size: 4rem;
  @include google_font;
  line-height: 1.5;
  &::first-letter {
    color: $accent_color_yellow;
  }
  @include mq-min(md) {
    font-size: min(6rem, 5.66vw);
    line-height: 1.2;
  }
}



.c-mv__heading--sub {
  font-size: 2rem;
  margin-bottom: 1em;
  @include mq-min(md) {
    margin-right: 16.66667%;
  }
}

.c-mv__text--sub {
  font-size: 1.4rem;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-right: 16.66667%;
  }
}

.c-mv__text--indent {
  font-size: 1.4rem;
  padding-left: 1em;
  text-indent: -1em;
  @include mq-min(md) {
    font-size: 1.6rem;
    margin-right: 16.66667%;
  }
}


// ページごとに画像差し替え

.guestroom .c-mv__text-wrap::after {
  background-image: url(/assets/images/guestroom/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/guestroom/mv_pc.jpg);
  }
}

.sightseeing .c-mv__text-wrap::after {
  background-image: url(/assets/images/sightseeing/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/sightseeing/mv_pc.jpg);
  }
}

.agreement .c-mv__text-wrap::after {
  background-image: url(/assets/images/agreement/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/agreement/mv_pc.jpg);
  }
}

.policy .c-mv__text-wrap::after {
  background-image: url(/assets/images/policy/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/policy/mv_pc.jpg);
  }
}

.about .c-mv__text-wrap::after {
  background-image: url(/assets/images/about/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/about/mv_pc.jpg);
  }
}

.access .c-mv__text-wrap::after {
  background-image: url(/assets/images/access/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/access/mv_pc.jpg);
  }
}

.faq .c-mv__text-wrap::after {
  background-image: url(/assets/images/faq/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/faq/mv_pc.jpg);
  }
}

.contact .c-mv__text-wrap::after {
  background-image: url(/assets/images/contact/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/contact/mv_pc.jpg);
  }
}

.measures .c-mv__text-wrap::after {
  background-image: url(/assets/images/measures/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/measures/mv_pc.jpg);
  }
}

.news .c-mv__text-wrap::after {
  background-image: url(/assets/images/news/common/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/news/common/mv_pc.jpg);
  }
}

.error .c-mv__text-wrap::after {
  background-image: url(/assets/images/404/mv_sp.jpg);
  @include mq-min(md) {
    background-image: url(/assets/images/404/mv_pc.jpg);
  }
}
@use '../setting/index' as *;

// TOPページ MV
.p-mv {
  @include mq-min(md) {
    position: relative;
    max-width: 1920px;
    padding-top: min(120px, 8.824vw);
    margin: auto;
  }
}


.p-mv__inner {
  @include mq-min(md) {
    max-width: 1740px;
    padding: 0 30px 0 180px;
    margin-left: auto;
    z-index: 5;
  }
}

.p-mv__heading-mask {
  position: relative;
  @include mq-min(md) {
    max-width: 1440px;
  }
}

.p-mv__heading-swiper-groupe {
  position: relative;
  @include mq-min(md) {
    max-width: 1440px;
    overflow: hidden;
  }
}

.p-mv__heading-wrap {
  position: absolute;
  top: min(230px, 30.667vw);
  left: min(60px, 8vw);
  line-height: 1.35;
  z-index: 5;
  @include mq-min(md) {
    top: 50%;
    left: max(-178px, -9.271vw);
    bottom: 0;
    transform: translateY(-50%);
    line-height: 1.5
  }
}

.p-mv__heading {
  color: #fff;
  text-shadow: 0px 0px 3px #000;
  font-size: min(3.2rem, 4.267vw);
  font-family: 'Noto Serif JP', serif;
  letter-spacing: 0.1em;

  @include mq-min(md) {
    font-size: min(4rem, 2.083vw);
    text-shadow: 0px 0px 6px #000000;
  }
}

.p-mv__heading-large {
  display: block;
  font-size: min(3.6rem, 4.8vw);
  margin-top: 6px;
  @include mq-min(md) {
    font-size: min(5rem, 2.604vw);
    margin-top: min(10px, 0.521vw);
  }
}

.p-mv__text--en {
  font-size: min(3.2rem, 4.267vw);
  @include google_font;
  color: #fff;
  letter-spacing: 0.1em;
  text-shadow: 0px 0px 3px #000;
  margin-top: 20px;
  @include mq-min(md) {
    font-size: min(3rem, 1.5625vw);
    margin-top: min(50px, 2.604vw);
    text-shadow: 0px 0px 6px #000;
  }
}
.p-mv__text--en span {
  display: block;
}

.p-mv__text {
  color: #fff;
  font-size: min(2.8rem, 3.733vw);
  text-shadow: 0px 0px 3px #000;
  margin-top: 9px;
  line-height: 1.5;
  font-family: 'Noto Serif JP', serif;
  @include mq-min(md) {
    font-size: clamp(1.2rem, 0.9375vw ,1.8rem);
    line-height: 2;
    margin-top: min(20px, 1.042vw);
    text-shadow: 0px 0px 6px #000;
  }
}

.p-mv__text span {
  display: block;
}

.p-mv__black-heading-wrap {
  display: none;
  @include mq-min(md) {
    display: block;
    position: absolute;
    top: 50%;
    left: max(-178px, -9.271vw);
    bottom: 0;
    transform: translateY(-50%);
    line-height: 1.5
  }
}

.p-mv__black-heading {
  font-size: min(3.2rem, 4.267vw);
  font-family: 'Noto Serif JP', serif;
  letter-spacing: 0.1em;
  @include mq-min(md) {
    font-size: min(4rem, 2.083vw);
    clip-path: polygon(0 0, 20% 0, 20% 100%, 0% 100%);
  }
}

.p-mv__black-heading-large {
  display: block;
  font-size: min(3.6rem, 4.8vw);
  margin-top: 6px;
  @include mq-min(md) {
    font-size: min(5rem, 2.604vw);
    margin-top: min(10px, 0.521vw);
  }
}

.p-mv__black-text--en {
  color: #333;
  font-size: min(3.2rem, 4.267vw);
  @include google_font;
  letter-spacing: 0.1em;
  margin-top: 20px;
  @include mq-min(md) {
    font-size: min(3rem, 1.5625vw);
    margin-top: min(50px, 2.604vw);
    clip-path: polygon(0 0, 20% 0, 20% 100%, 0% 100%);
  }
}
.p-mv__black-text--en span {
  display: block;
}

.p-mv__black-text {
  font-size: min(2.8rem, 3.733vw);
  margin-top: 9px;
  line-height: 1.5;
  font-family: 'Noto Serif JP', serif;
  @include mq-min(md) {
    font-size: clamp(1.2rem, 0.9375vw ,1.8rem);
    line-height: 2;
    margin-top: min(20px, 1.042vw);
    clip-path: polygon(0 0, 20% 0, 20% 100%, 0% 100%);
  }
}

.p-mv__black-text span {
    display: block;
}

.p-mv__slide-wrap {
  width: min(1440px, 100%);
  margin-left: 0;
  margin-right: 0;
}

.p-mv__slide-item {
  position: relative;
}

.p-mv__slide-item img {
  @include mq-min(md) {
    width: 100%;
    height: 100%;
  }
}

// globalページ
html[lang=en] .p-mv__heading {
  @include google_font;
}
html[lang=ko] .p-mv__heading {
  font-family: $ko_base_font;
}
html[lang=zh-CN] .p-mv__heading {
  font-family: $zh-CN_base_font;
}
html[lang=zh-tw] .p-mv__heading {
  font-family: $zh-tw_base_font;
}

html[lang=en] .p-mv__black-heading {
  @include google_font;
}
html[lang=ko] .p-mv__black-heading {
  font-family: $ko_base_font;
}
html[lang=zh-CN] .p-mv__black-heading {
  font-family: $zh-CN_base_font;
}
html[lang=zh-tw] .p-mv__black-heading {
  font-family: $zh-tw_base_font;
}


html[lang=ko] .p-mv__heading-wrap {
  @include mq-min(md) {
    left: max(-163px, -8.49vw);
  }
}
html[lang=ko] .p-mv__black-heading-wrap {
  @include mq-min(md) {
    left: max(-163px, -8.49vw);
  }
}

html[lang=zh-CN] .p-mv__heading-wrap,
html[lang=zh-tw] .p-mv__heading-wrap {
  @include mq-min(md) {
    left: max(-118px, -6.146vw);
  }
}
html[lang=zh-CN] .p-mv__black-heading-wrap,
html[lang=zh-tw] .p-mv__black-heading-wrap {
  @include mq-min(md) {
    left: max(-118px, -6.146vw);
  }
}


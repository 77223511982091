@use '../setting/index' as *;

.p-contact-indicator {
  position: relative;
  width: min(530px, 70.667vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto 0;
  @include mq-min(md) {
    width: 535px;
    margin-top: 80px;
  }
}

.p-contact-indicator::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 30px;
  right: 30px;
  transform: translateY(-50%);
  height: min(6px, 0.8vw);
  background-color: #DBDBDB;
  @include mq-min(md) {
    height: 6px;
  }
}

.p-contact-indicator__item {
  position: relative;
  display: grid;
  place-content: center;
  width: min(100px, 13.334vw);
  height: min(100px, 13.334vw);
  border-radius: 50%;
  background-color: #ACC9C3;
  @include mq-min(md) {
    width: 70px;
    height: 70px;
  }
}

.p-contact-indicator__item.is-current {
  background-color: #034A3B;
}


.p-contact-indicator__num {
  font-size: min(4rem, 5.334vw);
  color: #fff;
  @include google_font;
  line-height: 1;
  @include mq-min(md) {
    font-size: 3rem;
  }
}


.p-contact-indicator__text {
  position: absolute;
  left: 50%;
  top: 118%;
  transform: translateX(-50%);
  width: 180%;
  font-size: 1.4rem;
  text-align: center;
  @include mq-min(md) {
    width: 200%;
    font-size: 1.6rem;
  }
}

.p-contact {
  padding: 80px 0 60px;
  @include mq-min(md) {
    padding: 81px 30px 200px;
  }
}

.p-contact__inenr {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1200px;
    margin: auto;
    padding: 70px 30px;
    border: 1px solid #dbdbdb;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
}

.p-contact-form {
  padding-bottom: 10px;
  @include mq-min(md) {
    margin-top: 30px;
  }
}

.p-contact-form__content {
  @include mq-min(md) {
    display: flex;
    max-width: 1000px;
    margin: auto;
  }
}

.p-contact-form__content dt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 20px 0 6px 10px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  @include mq-min(md) {
    align-items: center;
    flex-wrap: wrap;
    width: 25%;
    min-width: 240px;
    font-size: 1.6rem;
    padding: 20px 0 20px 20px;
  }
}

.p-contact-form__content.start dt {
  @include mq-min(md) {
    align-items: flex-start;
  }
}

.p-contact-form__required {
  display: grid;
  place-content: center;
  width: 49px;
  height: 26px;
  font-size: 1.4rem;
  color: #fff;
  line-height: 1;
  background-color: #1F8570;
  @include mq-min(md) {
    font-size: 1.4rem;
  }
}

.p-contact-form__content dd {
  font-size: 1.4rem;
  padding: 10px 10px 0;
  @include mq-min(md) {
    width: 75%;
    font-size: 1.6rem;
    padding: 20px 28px;
    border-bottom: 2px solid #DBDBDB;
  }
}

.p-contact-form__content dd input[type=text],
.p-contact-form__content dd input[type=email],
.p-contact-form__content dd input[type=tel] {
  width: 100%;
  padding: 20px 10px;
  background-color: #F5F5F5;
  @include mq-min(md) {
    padding: 20px;
  }
}

.p-contact-form__content dd input[type=text][name=post-num] {
  width: 89%;
  padding: 20px 10px;
  background-color: #F5F5F5;
  @include mq-min(md) {
    width: min(400px, 100%);
    padding: 20px;
  }
}

.p-contact-form__content dd input::placeholder {
  color: #A7A7A7;
}

.p-contact-form__content dd textarea {
  width: 100%;
  padding: 20px 10px;
  background-color: #F5F5F5;
  @include mq-min(md) {
    padding: 20px;
  }
}

.p-contact-form__content dd textarea::placeholder {
  color: #A7A7A7;
}

.p-contact-form__content dd input[type=checkbox] {
  appearance: none;
}

.p-contact-form__postal-mark {
  padding-right: 15px;
  @include mq-min(md) {
    padding-right: 20px;
  }
}

.p-contact-form__agree span {
  position: relative;
  display: inline-block;
  width: fit-content;
  margin-left: 26px;
  cursor: pointer;
}
.p-contact-form__agree span::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -26px;
  width: 16px;
  height: 16px;
  border: 1px solid #231815;
  background: #fff;
  margin-top: -8px;
}
.p-contact-form__agree span::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -21px;
  width: 5px;
  height: 11px;
  margin-top: -7px;
  transform: rotate(45deg);
  border-right: 2px solid #1F8570;
  border-bottom: 2px solid #1F8570;
  opacity: 0;
}

.p-contact-form__content dd input[type=checkbox]:checked + span::after {
  opacity: 1;
}

.p-contact-form__notice {
  padding-left: 1em;
  text-indent: -1em;
  margin-top: 10px;
  border-bottom: 1px solid #DBDBDB;
  padding-bottom: 10px;
  @include mq-min(md) {
    font-size: 1.4rem;
    padding-bottom: 0;
    border-bottom: none;
  }
}
.p-contact-form__notice a {
  color: #1F8570;
  text-decoration: underline;
}

.p-contact-form__button-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: min(560px, 74.667vw);
  margin: 30px auto 0;
  @include mq-min(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    width: 100%;
    margin: 50px auto 0;
  }
}

.p-contact-form__button-wrap .c-button-transparent,
.p-contact-form__button-wrap .c-button--right-border {
  @include mq-min(md) {
    width: 283px;
  }
}

.p-contact-banner {
  position: relative;
  display: grid;
  place-content: center;
  width: min(690px, 92vw);
  min-height: 228px;
  background: url(/assets/images/contact/banner_bg_sp.jpg) no-repeat center / cover;
  margin: 60px auto 0;
  @include mq-min(md) {
    width: min(1200px, 100%);
    min-height: 300px;
    margin: 70px auto 0;
    background: url(/assets/images/contact/banner_bg_pc.jpg) no-repeat center / cover;
    cursor: default;
    pointer-events: none;
  }
}
.p-contact-banner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba($color: #034a3b, $alpha: .4);
}
.p-contact-banner:hover {
  @include mq-min(md) {
    pointer-events: none;
  }
}

.p-contact-banner__text {
  position: relative;
  text-align: center;
  color: #fff;
  @include mq-min(md) {
    pointer-events: none;
  }
}

.p-contact-banner__text li {
  @include mq-min(md) {
    pointer-events: none;
  }
}

.p-contact-banner__text-first {
  font-size: 1.4rem;
  @include mq-min(md) {
    font-size: 1.8rem;
  }
}

.p-contact-banner__text-second {
  @include google_font;
  font-size: 3rem;
  margin-top: 4px;
  @include mq-min(md) {
    font-size: 5rem;
  }
}

.p-contact-banner__text-second span {
  font-size: 1.6rem;
  padding-right: 10px;
  @include mq-min(md) {
    font-size: 3rem;
    padding-right: 20px;
  }
}

.p-contact-banner__text-third {
  font-size: 1.6rem;
  margin-top: 10px;
  @include mq-min(md) {
    font-size: 1.8rem
  }
}

.p-contact-banner__text-third span {
  @include google_font;
  padding-left: 10px;
  @include mq-min(md) {
    font-size: 2rem;
  }
}

.p-contact__thanks-message {
  font-size: 1.6rem;
  padding: 0 30px;
  @include mq-min(md) {
    padding-top: 20px;
    text-align: center;
  }
}
@use '../setting/index' as *;

%c-button {
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 58px;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  letter-spacing: 0.3em;
  background: url(/assets/images/common/button_bg_sp.jpg) no-repeat center / cover;
  
  @include mq-min(md) {
    min-height: 80px;
    font-size: 1.6rem;
    background: url(/assets/images/common/button_bg_pc.jpg) no-repeat center / cover;
    transition: opacity .3s;
  }

  &:hover {
    @include mq-min(md) {
      opacity: .7;
    }
  }
}

.c-button {
  @extend %c-button;
  padding: 0 1em;
}

.c-button--right-border {
  @extend %c-button;
  padding: 0 15px;
  @include mq-min(md) {
    padding: 0 30px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
    width: 30px;
    border-top: 1px solid #333;
    @include mq-min(md) {
      width: 60px;
      right: -30px;
    }
  }
}

%c-button-small {
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 40px;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  background: url(/assets/images/common/small_button_bg_sp.jpg) no-repeat center / cover;
  
  @include mq-min(md) {
    min-height: 60px;
    font-size: 1.6rem;
    background: url(/assets/images/common/small_button_bg_pc.jpg) no-repeat center / cover;
    transition: opacity .3s;
  }

  &:hover {
    @include mq-min(md) {
      opacity: .7;
    }
  }
}

.c-button-small {
  @extend %c-button-small;
  padding: 0 1em;
}

.c-button-small--right-border {
  @extend %c-button-small;
  padding: 0 10px;
  @include mq-min(md) {
    padding: 0 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 20px;
    border-top: 1px solid #333;
    @include mq-min(md) {
      width: 40px;
      right: -20px;
    }
  }
}

.c-button-transparent {
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
  min-height: 58px;
  font-size: 1.4rem;
  text-align: center;
  padding: 0 1em;
  border: 1px solid #333;
  
  @include mq-min(md) {
    min-height: 80px;
    font-size: 1.6rem;
    padding: 0 1em;
    transition: background-color .3s;
  }

  &:hover {
    @include mq-min(md) {
      background-color: #d1e3e0;
    }
  }
}
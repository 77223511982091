@use '../setting/index' as *;

.p-form-wrap {
  display: none;

  @include mq-min(md) {
    position: relative;
    display: block;
    padding: 30px 0;
    margin-top: 10px;
    background: linear-gradient(135deg, #dccfb9 0%, #cde5d8 100%);
    border: 2px solid #fff;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
  }
}

.p-form-wrap::before {
  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 19px;
    left: -21px;
    width: 80px;
    border-top: 1px solid #707070;
    transform: rotate(-45deg);
  }
}

.p-form-wrap::after {
  @include mq-min(md) {
    content: '';
    position: absolute;
    bottom: 19px;
    right: -21px;
    width: 80px;
    border-top: 1px solid #707070;
    transform: rotate(-45deg);
  }
}

.p-form-wrap__inner {
  @include mq-min(xl) {
    padding: 0 86px;
    column-gap: 20px;
  }

  @include mq-min(md) {
    padding-right: min(30px, 2.381vw);
    padding-left: min(30px, 2.381vw);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    column-gap: 10px;
  }
}

.p-form-tab {
  display: flex;

  @include mq-min(lg) {
    column-gap: 10px;
  }

  @include mq-min(md) {
    column-gap: 5px;
  }
}


.p-form-tab__item {
  @include mq-min(lg) {
    width: 120px;
    height: 100px;
    padding: 12px;
  }

  @include mq-min(md) {
    width: 100px;
    height: 100px;
    padding: 12px 0;
    background: #fff;
    border: 3px solid #fff;
    opacity: .4;
    cursor: pointer;
  }
}

.p-form-tab__item.is-current {
  background: #fff;
  border: 3px solid #fff3e9;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  pointer-events: none;
}

.p-form-tab__image {
  display: block;
  width: 50px;
  height: 50px;
  margin: auto;
}

.p-form-tab__text {
  font-size: 1.6rem;
  text-align: center;
}

.p-form.is-current {
  display: block;
}

.p-form {
  display: none;

  @include mq-min(xl) {
    width: auto;
  }

  @include mq-min(md) {
    width: 71%;
  }
}

.p-form--secondary {
  @include mq-min(lg) {
    flex-wrap: nowrap;
  }

  @include mq-min(md) {
    display: none;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    flex: 1;
  }
}

.p-form--secondary.is-current {
  display: flex;
}

.p-form__inner {
  @include mq-min(lg) {
    flex-wrap: nowrap;
  }

  @include mq-min(md) {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.p-form__label {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 6px;
}

.p-form__checkin {
  position: relative;
  width: min(178px, 14.127vw);
  min-width: 144px;
  font-weight: bold;
}

.p-form__checkin input {
  @include mq-min(xl) {
    padding: 14px 20px 14px 20px;
  }

  @include mq-min(md) {
    width: 100%;
    font-size: 1.6rem;
    background-color: #fff;
    padding: 10px;
  }
}

.p-form__checkout {
  position: relative;
  width: min(178px, 14.127vw);
  min-width: 144px;
  font-weight: bold;
}

.p-form__checkout input {
  @include mq-min(xl) {
    padding: 14px 20px 14px 20px;
  }

  @include mq-min(md) {
    width: 100%;
    font-size: 1.6rem;
    background-color: #fff;
    padding: 10px;
  }
}

.p-form__adult {
  @include mq-min(xl) {
    width: 100px;
  }

  @include mq-min(lg) {
    width: min(100px, 100%);
  }

  @include mq-min(md) {
    width: 64px;
  }
}

.p-form__adult select {
  @include mq-min(xl) {
    padding: 14px 40px 14px 20px;
  }

  @include mq-min(md) {
    width: 100%;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: #fff;
    padding: 10px;
  }
}

.p-form__select-wrap {
  position: relative;
}

.p-form__select-wrap::after {
  @include mq-min(xl) {
    left: 66px;
  }

  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 2px;
    left: 46px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #333 transparent transparent transparent;
  }
}

.p-form__select-wrap--airline {
  position: relative;
}

.p-form__select-wrap--airline::after {
  @include mq-min(lg) {
    left: 66px;
  }

  @include mq-min(md) {
    content: '';
    position: absolute;
    top: 2px;
    left: 46px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #333 transparent transparent transparent;
  }
}

.p-form__child {
  @include mq-min(xl) {
    width: 100px;
  }

  @include mq-min(lg) {
    width: min(100px, 100%);
  }

  @include mq-min(md) {
    width: 64px;
  }
}

.p-form__child select {
  @include mq-min(xl) {
    padding: 14px 40px 14px 20px;
  }

  @include mq-min(md) {
    width: 100%;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: #fff;
    padding: 10px;
  }
}

.p-form__dropdown {
  display: none;
}

.p-form__button {
  @include mq-min(xl) {
    min-width: 160px;
    min-height: 100px;
  }

  @include mq-min(lg) {
    min-width: 11.111vw;
    min-height: 6.349vw;
    width: auto;
  }

  @include mq-min(md) {
    display: grid;
    place-content: center;
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
    width: 100%;
    height: 45px;
    border: 1px solid #fff;
    background: url(/assets/images/top/top_from_button_bg.jpg) no-repeat center / cover;
    transition: opacity .3s;
  }

  &:hover {
    @include mq-min(md) {
      opacity: .7;
    }
  }
}

.p-form__link {
  @include mq-min(xl) {
    padding: 0 86px;
  }

  @include mq-min(md) {
    padding-right: min(30px, 2.381vw);
    padding-left: min(30px, 2.381vw);
    display: none;
    align-items: center;
    justify-content: flex-end;
    column-gap: 30px;
    font-size: 1.4rem;
    margin-top: 10px;
  }
}

.p-form__link.is-current {
  display: flex;
}

.p-form__link-item {
  position: relative;
  transition: opacity .3s;

  &:hover {
    @include mq-min(md) {
      opacity: .5;
    }
  }
}

.p-form__link-item::before {
  content: '';
  position: absolute;
  top: 4px;
  left: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 9px;
  border-color: transparent transparent transparent #333;
}

.p-form__airline-select-wrap {
  position: relative;
}

.p-form__airline select {
  @include mq-min(lg) {
    padding: 14px 40px 14px 20px;
  }

  @include mq-min(md) {
    max-width: 220px;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 10px;
    background-color: #fff;
  }
}

.p-form__date--airline {
  position: relative;
}

.p-form__date--airline input {
  @include mq-min(lg) {
    padding: 14px 50px 14px 20px;
  }

  @include mq-min(md) {
    max-width: 178px;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 10px;
    background-color: #fff;
  }
}

.p-form__select--airline {
  position: relative;

  @include mq-min(lg) {
    max-width: 100px;
    width: 100%;
    min-width: 88px;
  }

  @include mq-min(md) {
    width: 74px;
  }
}

.p-form__select--airline select {
  @include mq-min(lg) {
    padding: 14px 40px 14px 20px;
  }

  @include mq-min(md) {
    max-width: 100px;
    width: 100%;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: #fff;
    padding: 10px;
    background-color: #fff;
  }
}

.p-form__button--air {
  @include mq-min(lg) {
    min-width: 160px;
    min-height: 100px;
    width: auto;
  }

  @include mq-min(md) {
    display: grid;
    place-content: center;
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
    width: 100%;
    height: 45px;
    border: 1px solid #fff;
    background: url(/assets/images/top/top_from_button_bg.jpg) no-repeat center / cover;
    margin-left: auto;
    transition: opacity .3s;
  }

  &:hover {
    @include mq-min(md) {
      opacity: .7;
    }
  }
}

.p-form-endtext {
  @include mq-min(xl) {
    padding: 0 80px;
  }

  @include mq-min(md) {
    text-align: right;
    font-size: 1.2rem;
    margin-top: 5px;
    display: flex;
    padding-right: min(30px, 2.381vw);
    padding-left: min(30px, 2.381vw);
    justify-content: flex-end;
  }
}

// globalページ
html[lang=en] .p-form-wrap,
html[lang=ko] .p-form-wrap,
html[lang=zh-CN] .p-form-wrap,
html[lang=zh-tw] .p-form-wrap {
  @include mq-min(md) {
    margin-top: 23PX;
  }
}

html[lang=en] .p-form__link,
html[lang=ko] .p-form__link,
html[lang=zh-CN] .p-form__link,
html[lang=zh-tw] .p-form__link {
  @include mq-min(md) {
    max-width: 994px;
    padding: 0 20px;
  }
}
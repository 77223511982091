@use '../setting/index' as *;

.p-about-section {
  padding-bottom: 60px;
  @include mq-min(md) {
    padding: 50px 0 200px;
  }
}

.p-about-section__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1060px;
    margin: auto;
  }
}

.p-about-description {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  @include mq-min(md) {
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.6rem;
  }
}

.p-about-description dt {
  font-weight: bold;
  padding: 0 10px 10px;
  margin-top: 30px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;

  @include mq-min(md) {
    width: 25%;
    padding: 30px 20px;
    margin-top: 0;
  }
}

.p-about-description dd {
  padding: 10px 10px 0;
  @include mq-min(md) {
    width: 75%;
    padding: 30px 20px;
    border-bottom: 2px solid #DBDBDB;
  }
}
@use '../setting/index' as *;

.u-pc {
  display: none;
  @include mq-min(md) {
    display: block;
  }
}
.u-sp {
  display: block;
  @include mq-min(md) {
    display: none;
  }
}

.u-hidden {
  overflow: hidden;
  @include mq-min(md) {
    overflow: initial;
  }
}

@use '../setting/index' as *;

.p-agreement-section {
  padding-bottom: 65px;
  overflow: hidden;

  @include mq-min(md) {
    padding-bottom: 80px;
  }
}

.p-agreement-section__inner {
  padding: 0 30px;
  @include mq-min(md) {
    max-width: 1260px;
    margin: auto;
  }
}

.p-agreement-description dt {
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 30px 10px 10px 10px;
  border-bottom: 2px solid;
  border-image: $accent_color_gradation;
  border-image-slice: 1;
  @include mq-min(md) {
    font-size: 2rem;
    padding: 80px 20px 20px 20px;
  }
}

.p-agreement-description dd {
  width: 100%;
  font-size: 1.4rem;
  @include mq-min(md) {
    font-size: 1.6rem;
  }
}

.p-agreement-description__main-sentence {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 4em;
  text-indent: -4.2em;
  margin-left: 10px;
  @include mq-min(md) {
    padding-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
  }
}

.p-agreement-description__main-sentence--indent-large {
  padding-top: 10px;
  padding-right: 10px;
  text-indent: -5.2em;
  padding-left: 7.2rem;
  margin-left: 10px;
  @include mq-min(md) {
    margin-left: 50px;
    margin-right: 50px;
  }
}

.p-agreement-description__sub-sentence {
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 2em;
  text-indent: -1.6em;
  margin-left: 38px;
  @include mq-min(md) {
    margin-left: 80px;
    margin-right: 50px;
  }
}

.p-agreement-description__list {
  padding-left: 2.7em;
  text-indent: -2.7em;
  margin-left: 54px;
  @include mq-min(md) {
    margin-left: 100px;
    margin-right: 50px;
  }
}

.p-agreement-description__list li {
  margin-top: 10px;
}

.p-agreement-description__last-sentence {
  text-align: right;
  margin-top: 30px;
  @include mq-min(md) {
    margin-top: 80px;
  }
}

.p-agreement-description__notice {
  padding-left: 40px;
  @include mq-min(md) {
    padding-left: 80px;
    margin-right: 50px;
  }
}

.p-agreement-description__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 10px 0 10px 40px;
  @include mq-min(md) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    padding: 20px 50px 10px 80px;
  }
}

.p-agreement-description__grid li {
  display: grid;
  place-content: center;
  min-height: 38px;
  border: 1px solid #707070;
}

.p-agreement-description__grid-item01 {
  grid-area: 1 / 1 / 2 / 2;
  @include mq-min(md) {
    grid-area: 1 / 1 / 2 / 2; 
  }
}
.p-agreement-description__grid-item02 {
  grid-area: 1 / 2 / 2 / 3; 
  @include mq-min(md) {
    grid-area: 1 / 2 / 2 / 3; 
  }
}
.p-agreement-description__grid-item03 {
  grid-area: 2 / 1 / 3 / 2; 
  @include mq-min(md) {
    grid-area: 1 / 3 / 2 / 4; 
  }
}
.p-agreement-description__grid-item04 {
  grid-area: 2 / 2 / 3 / 3; 
  @include mq-min(md) {
    grid-area: 1 / 4 / 2 / 5; 
  }
}

.p-agreement-description__grid--secondary-scroll-wrap {
  width: calc(100% + 30px);
  overflow-x: scroll;
  @include mq-min(md) {
    width: 100%;
    overflow-x: initial;
  }
}

.p-agreement-description__grid--secondary {
  display: grid;
  grid-template-columns: repeat(5, 195px) 30px;
  grid-template-rows: repeat(5, 1fr);
  width: fit-content;
  margin: 10px 0 10px 40px;
  padding-right: 10px;
  @include mq-min(md) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    width: 91%;
    margin: 20px 10px 10px auto;
    padding-right: 0;
  }
}

.p-agreement-description__grid--secondary-item01 { grid-area: 1 / 1 / 2 / 2; }
.p-agreement-description__grid--secondary-item02 { grid-area: 1 / 2 / 2 / 3; }
.p-agreement-description__grid--secondary-item03 { grid-area: 1 / 3 / 2 / 4; }
.p-agreement-description__grid--secondary-item04 { grid-area: 1 / 4 / 2 / 5; }
.p-agreement-description__grid--secondary-item05 { grid-area: 1 / 5 / 2 / 6; }
.p-agreement-description__grid--secondary-item06 { grid-area: 2 / 1 / 3 / 2; }
.p-agreement-description__grid--secondary-item07 { grid-area: 2 / 2 / 3 / 3; }
.p-agreement-description__grid--secondary-item08 { grid-area: 2 / 3 / 3 / 4; }
.p-agreement-description__grid--secondary-item09 { grid-area: 2 / 4 / 3 / 5; }
.p-agreement-description__grid--secondary-item10 { grid-area: 2 / 5 / 3 / 6; }
.p-agreement-description__grid--secondary-item11 { grid-area: 3 / 1 / 4 / 2; }
.p-agreement-description__grid--secondary-item12 { grid-area: 3 / 2 / 4 / 3; }
.p-agreement-description__grid--secondary-item13 { grid-area: 3 / 3 / 4 / 4; }
.p-agreement-description__grid--secondary-item14 { grid-area: 3 / 4 / 4 / 5; }
.p-agreement-description__grid--secondary-item15 { grid-area: 3 / 5 / 4 / 6; }
.p-agreement-description__grid--secondary-item16 { grid-area: 4 / 1 / 5 / 2; }
.p-agreement-description__grid--secondary-item17 { grid-area: 4 / 2 / 5 / 3; }
.p-agreement-description__grid--secondary-item18 { grid-area: 4 / 3 / 5 / 4; }
.p-agreement-description__grid--secondary-item19 { grid-area: 4 / 4 / 5 / 5; }
.p-agreement-description__grid--secondary-item20 { grid-area: 4 / 5 / 5 / 6; }
.p-agreement-description__grid--secondary-item21 { grid-area: 5 / 1 / 6 / 2; }
.p-agreement-description__grid--secondary-item22 { grid-area: 5 / 2 / 6 / 3; }
.p-agreement-description__grid--secondary-item23 { grid-area: 5 / 3 / 6 / 4; }
.p-agreement-description__grid--secondary-item24 { grid-area: 5 / 4 / 6 / 5; }
.p-agreement-description__grid--secondary-item25 { grid-area: 5 / 5 / 6 / 6; }

.p-agreement-description__grid--secondary li {
  display: grid;
  place-content: center;
  min-height: 36px;
  border: 1px solid #707070;
  @include mq-min(md) {
    max-width: 220px;
    min-height: 40px;
    font-size: min(1.6rem, 1.455vw);
  }
}

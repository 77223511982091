@use '../setting/index' as *;

* {
  box-sizing: border-box;
  letter-spacing: 0.05em;
}

::before,
::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  font-family: $base_font;
  font-weight: 500;
  color: $font_color;
  overflow-x: hidden;
}

html[lang=ko] {
  font-family: $ko_base_font;
}
html[lang=zh-CN] {
  font-family: $zh-CN_base_font;
}
html[lang=zh-tw] {
  font-family: $zh-tw_base_font;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

@use '../setting/index' as *;

.l-sp-float-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  @include mq-min(md) {
    display: none;
  }
}

.l-sp-float-button__inner {
  display: flex;
}

%sp-float-button__item {
  display: grid;
  place-content: center;
  width: 50%;
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  min-height: 60px;
}

.l-sp-float-button__item--gold {
  @extend %sp-float-button__item;
  background: url(/assets/images/common/sp_float_button_gold.jpg) no-repeat center / cover;
}

.l-sp-float-button__item--green {
  @extend %sp-float-button__item;
  background: url(/assets/images/common/sp_float_button_green.jpg) no-repeat center / cover;
}

html[lang=en] .l-sp-float-button__item--green,
html[lang=ko] .l-sp-float-button__item--green,
html[lang=zh-CN] .l-sp-float-button__item--green,
html[lang=zh-tw] .l-sp-float-button__item--green {
  width: 100%;
}
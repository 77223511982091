@use '../setting/index' as *;

.p-amenity__heading {
  font-size: 1.6rem;
  margin-top: 26px;
  font-weight: bold;
  // position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  @include mq-min(md) {
    margin-top: 45px;
  }
}
.p-amenity__heading::after {
  content: "";
  // position: absolute;
  height: 1px;
  background: $accent_color_gray;
  width: 100%;
}
.p-amenity__heading-bg {
  padding-right: 10px;
}
.p-amenity__4col {
  display: flex;
  flex-wrap: wrap;
  gap: 17px 10px;
  margin-top: 16px;
  @include mq-min(md) {
    padding: 0 5.83334%;
    gap: 26px 20px;
  }
}
.p-amenity__4col-item {
  width: calc((100% / 4) - 8px);
  @include mq-min(md) {
    width: calc(100% / 9);
    max-width: 100px;
  }
}
.p-amenity__4col-image {
  display: block;
  padding: 12px;
  background: #fff;
  border: 1px solid #DBDBDB;
  text-align: center;
  margin: 0 7.1286%;
  @include mq-min(md) {
    margin: 0 auto;
    max-width: 80px;
  }
}
.p-amenity__4col-text {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 7px;
}

// globalページ
html[lang=en] .p-amenity__heading,
html[lang=ko] .p-amenity__heading,
html[lang=zh-CN] .p-amenity__heading,
html[lang=zh-tw] .p-amenity__heading {
  @include mq-min(md) {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 62px;
  }
}

html[lang=en] .p-amenity__4col,
html[lang=ko] .p-amenity__4col,
html[lang=zh-CN] .p-amenity__4col,
html[lang=zh-tw] .p-amenity__4col {
  @include mq-min(md) {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 26px;
  }
}

html[lang=en] .p-amenity__4col-text,
html[lang=ko] .p-amenity__4col-text,
html[lang=zh-CN] .p-amenity__4col-text,
html[lang=zh-tw] .p-amenity__4col-text {
  font-weight: normal;
  @include mq-min(md) {
    font-size: 1.4rem;
  }
}

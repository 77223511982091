@use '../setting/index' as *;

.l-link {
  width: calc(100% - 60px);
  padding: 20px 0 17px;
  margin: auto;
  border-top: 1px solid #A7A7A7;

  @include mq-min(md) {
    width: min(1200px, 100%);
  }
}

.l-link__inner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 7px;

  @include mq-min(md) {
    position: relative;
    flex-direction: row;
    align-items: center;
    column-gap: 5.39%;
    padding: 0 11.35%;
    margin: auto;
    z-index: 5;
  }
}

.l-link__item {
  position: relative;
  display: inline-block;
  font-size: 1.4rem;
  padding-right: 16px;

  @include mq-min(md) {
    display: block;
    padding-right: 18px;
    transition: opacity .3s;
  }

  &:hover {
    @include mq-min(md) {
      opacity: .5;
    }
  }
}

.l-link__item::after {
  content: '';
  position: absolute;
  top: 6px;
  right: 2px;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}



// 
.l-other-link {
  padding: 30px 0;
}

.l-other-link__list {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  justify-content: flex-start;
  margin-top: 30px;

  @include mq-min(md) {
    gap: 20px 30px;
    margin: 50px auto;
    max-width: 1134px;
  }
}

.l-other-link__list li {
  width: 100%;

  @include mq-min(md) {
    width: calc(100% / 3);
    max-width: 358px;
  }
}

.l-other-link__list li:not(:first-child) {
  margin-top: 10px;

  @include mq-min(md) {
    margin-top: 0;
  }
}

.l-other-link__item {
  display: flex;
  border: 1px solid $accent_color_gray;
  transition: .5s all ease;
}

.l-other-link__item:hover {
  opacity: .6;
}

.l-other-link__item-image {
  width: 36.55556%;

  @include mq-min(md) {
    width: 35.7542%;
  }
}

.l-other-link__item-text {
  display: flex;
  align-items: center;
  width: calc(100% - 36.55556%);
  // border: 1px solid $accent_color_gray;
  border-left: none;
  padding: 0 15px;
  font-size: 1.6rem;

  @include mq-min(md) {
    width: calc(100% - 35.7542%);
  }
}